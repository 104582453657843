import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  dashboardUserConfig: {
    userId: null,
    userEmail: "",
    show: false,
    appConfig: "",
  },
};

//#region open modal dashboard user config
const openModalUserConfig = (state, userId, userEmail, appConfig) => {
  return updateObject(state, {
    dashboardUserConfig: updateObject(state.dashboardUserConfig, {
      userId,
      userEmail,
      show: true,
      appConfig,
    }),
  });
};

const closeModalUserConfig = (state) => {
  return updateObject(state, {
    dashboardUserConfig: updateObject(state.dashboardUserConfig, {
      show: false,
      userId: null,
      userEmail: "",
      appConfig: "",
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL_USER_CONFIG:
      return openModalUserConfig(
        state,
        action.userId,
        action.userEmail,
        action.appConfig
      );
    case actionTypes.CLOSE_MODAL_USER_CONFIG:
      return closeModalUserConfig(state);

    default:
      return state;
  }
};

export default reducer;
