import React, { Fragment } from "react";
import classes from "../Styles/Permisos.css";

import Button from "../../UI/Button/Button";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from '@material-ui/icons/Delete';

const EditActions = (props) => {
  const {
    info,
    formIsValid,
    inEdit,
    submitHandler,
    onEditOrCancelClick,
    onGuardarCambiosClick,
    onEliminarClick,
    itemTooltip,
  } = props;

  return (
    <Fragment>
      <div className={classes.actionsContainer}>
        {!info ? (
          <Button
            style={{
              color: formIsValid ? "rgb(9, 212, 255)" : "gray",
              backgroundColor: "rgb(255 255 255 / 0%)",
              textTransform: 'capitalize',              
            }}
            onClick={() => submitHandler()}
            disabled={!formIsValid}
            classes={{
              root: classes.actionButton,
              label: classes.actionButtonLabel,
            }}
          >
            Crear
          </Button>
        ) : !inEdit ? (
          <div className={classes.actionIcons}>
            <Tooltip title={`Editar ${itemTooltip}`} placement="top">
              <IconButton onClick={() => onEditOrCancelClick()}>
                <EditIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title={`Eliminar ${itemTooltip}`} placement="top">
              <IconButton onClick={() => onEliminarClick()}>
                <DeleteIcon className={classes.actionIcon} />
              </IconButton>
            </Tooltip>
          </div>
        ) : (
          <div className={classes.actionIcons}>
            <Tooltip title="Guardar cambios" placement="top">
              <IconButton
                disabled={!formIsValid}
                onClick={() => onGuardarCambiosClick()}
              >
                <SaveIcon
                  className={classes.actionIcon}
                  style={{ color: !formIsValid && "gray" }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancelar cambios" placement="top">
              <IconButton onClick={() => onEditOrCancelClick(true)}>
                <CancelIcon style={{ color: "#ff3838" }} />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default EditActions;