import React, { useEffect, useState, Fragment, useCallback } from "react";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import classes from "../Styles/Permisos.css";
import { Typography } from "@material-ui/core";
import Spinner from "../../UI/Spinner/Spinner";
import { CardItems, NewActions, SelectControl } from "../Common";
import { CardAplicacion } from ".."; 

const CardAplicaciones = (props) => {
  const {
    loading,
    onLoadItems,
    loadingClientes,
    onLoadClientes,
  } = props;
  
  const [nuevoItem, setNuevoItem] = useState(false);
  const [item, setItem] = useState(null);
  const [loadingItem, setLoadingItem] = useState(false);

  useEffect(() => {
    onLoadItems();      
  }, [onLoadItems]);

  useEffect(() => {
    onLoadClientes();
  }, [onLoadClientes]);

  const onValueSelectChange = (item) => {
    setLoadingItem(true);
    setItem(item);
    setTimeout(() => setLoadingItem(false), 500);
  }

  const updateOnSuccess = useCallback((item) => {
    onLoadItems();
    setItem(item);
  }, [onLoadItems]);

  const onNuevoItemClick = () => {
    setItem(null);
    setNuevoItem(!nuevoItem);
  }

  return (
    <CardItems
      title={
        <div>
          <div className={classes.titleCard}>
            <Typography align="left" style={{ color: "#00BCD4", fontSize: '18px' }}>
              Aplicaciones
            </Typography>
            <NewActions 
              nuevoItem={nuevoItem}
              setNuevoItem={onNuevoItemClick}
            />
          </div>
          <div className={classes.titleCard}>
            {loading && loadingClientes ? (
              <div className={classes.spinnerItems}>
                <Spinner />
              </div>
            ) : !nuevoItem && (
              <SelectControl 
                dataSelectionId="aplicaciones"
                onSelectChange={onValueSelectChange}
                selectedItem={item}
              />
            )}
          </div>
        </div>
      }
      body={
        <div className={classes.cardsItems}>
          {loadingItem ? (
            <div className={classes.spinnerItems}>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              {nuevoItem && (
                <CardAplicacion 
                  nuevo 
                  close={() => setNuevoItem(!nuevoItem)} 
                  onSuccessUpdateOrCreate={updateOnSuccess}
                />
              )}
              {item && (
                <CardAplicacion 
                  info={item} 
                  onSuccessUpdateOrCreate={updateOnSuccess}
                />
              )}
            </Fragment>
          )}
        </div>
      }
    />
  );
}

const mapStateToProps = (state) => ({
  items: state.permisos.loadAplicaciones.items,
  loading: state.permisos.loadAplicaciones.loading,
  estaCargado: state.permisos.loadAplicaciones.estaCargado,
  loadingClientes: state.clientes.clientes.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadItems: () => dispatch(actions.loadAplicaciones()),
    onLoadClientes: () => dispatch(actions.loadClientesPermisos()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardAplicaciones);