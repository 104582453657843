import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import * as actions from "../../../store/actions/index";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Spinner from "../Spinner/Spinner";

const useStyles = makeStyles((theme) => ({
  text: {
    minWidth: '100px',
    "& .MuiInputBase-input": {
      fontSize: '14px',
      borderRadius: "0px"
    }
  }
}));

const VendedorSelector = (props) => {
  const classes = useStyles();
  const [vendedorId, setVendedorId] = useState(
    props.vendedorAsignado !== "" ? props.vendedorAsignado : -1
  );
  const [vendedores, setVendedores] = useState([]);
  const { onLoadVendedores, loading } = props;

  useEffect(() => {
    onLoadVendedores(props.dsn, (success) => setVendedores(success));
  }, [onLoadVendedores, props.dsn]);

  return loading ? (
    <Spinner personalSize="30px" />
  ) : (
    <Select
      style={{ minWidth: 120 }}
      value={vendedorId ? vendedorId : ""}      
      onChange={(event) => {
        setVendedorId(event.target.value);
        props.onChangeVendedor(event.target.value);
      }}
      className={classes.text}
    >
      <MenuItem key={-1} value={-1} style={{ fontSize: '12px', textTransform: 'uppercase', paddingBlock: '3px' }}>
        {" "}
        Sin asignar
      </MenuItem>
      {vendedores.map((vendedor) => (
        <MenuItem
          key={vendedor.idVendedor.trim()}
          value={vendedor.idVendedor.trim()}
          style={{ fontSize: '12px', textTransform: 'uppercase', paddingBlock: '3px' }}
        >
          {vendedor.nombre && vendedor.nombre.trimEnd()}
        </MenuItem>
      ))}
    </Select>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.vendedores.vendedoresPorEmpresa.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadVendedores: (dsn, success) =>
      dispatch(actions.loadVendedoresPorEmpresa(dsn, success)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendedorSelector);
