import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

import usuarios from "./store/reducers/usuarios";
import vendedores from "./store/reducers/vendedores";
import empresas from "./store/reducers/empresas";
import configuraciones from "./store/reducers/configuraciones";
import changeLogs from "./store/reducers/changeLogs";
import clientes from "./store/reducers/clientes";
import gruposDeVendedores from "./store/reducers/grupoDeVendedores";
import modals from "./store/reducers/modals";
import permisos from "./store/reducers/permisos";
import snackbar from './store/reducers/snackbar';
import indicadores from './store/reducers/indicadores';
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

const rootReducer = combineReducers({
  usuarios,
  vendedores,
  empresas,
  configuraciones,
  changeLogs,
  clientes,
  gruposDeVendedores,
  modals,
  permisos,
  snackbar,
  indicadores,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

const theme = createTheme({
  palette: {
    primary: {
      main: "#0CC5FF",
    },
    secondary: {
      main: "#b2ebf2",
    },
    black: {
      main: "#333333",
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider autoHideDuration={1500}>
          <App />
        </SnackbarProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
