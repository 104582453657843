import React from "react";
import classes from "../Styles/Permisos.css";

const CardItems = (props) => {
  const { title, body, style } = props;
  return (
    <div className={classes.cardItemsContainer} style={style}>
      <div className={classes.cardItemsTitle}>{title}</div>
      <div className={classes.cardItemsBody}>{body}</div>
    </div>
  );
};

export default CardItems;