// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormAjustesUsuario__FormContainer__Q7g6j {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.FormAjustesUsuario__FlexHorizontal__tb-d0 {\r\n  display: flex;\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.FormAjustesUsuario__Separador__0tKyY {\r\n  width: 31px;\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FormContainer": "FormAjustesUsuario__FormContainer__Q7g6j",
	"FlexHorizontal": "FormAjustesUsuario__FlexHorizontal__tb-d0",
	"Separador": "FormAjustesUsuario__Separador__0tKyY"
};
export default ___CSS_LOADER_EXPORT___;
