import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "../Styles/Permisos.css";
import { Typography } from "@material-ui/core";
import CardSeccion from "./CardSeccion";
import { CardItems, AddActions, NewActions, EditActions } from "../Common";
import { checkValidity } from "../../../assets/shared/utility";
import Spinner from "../../UI/Spinner/Spinner";
import { customControls } from "../Common/Controls";

const CardAplicacion = (props) => {
  const {
    nuevo,
    info,
    close,
    onSuccessUpdateOrCreate,
  } = props;
  
  const [items, setItems] = useState([]);
  const [nuevoItem, setNuevoItem] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);

  useEffect(() => {
    if(!nuevo && items.length === 0 && info !== undefined)
      setItems(info.secciones);
  }, [nuevo, items, info, setItems]);

  //#region Form

  const [controls, setControls] = useState({
    nombreItem: {
      elementType: "input",
      name: "nombreAplicacion",
      elementConfig: {
        type: "text",
        placeholder: "Nombre aplicación",
      },
      validation: {
        required: true,
      },
      disabled: info ? true : false,
      value: info ? info.nombre : "",
      valid: true,
      touched: false,
      errorMessage: "",
      elementStyle: { width: '100%' }
    }
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    updatedControl.value = event.target.value;
    const [isValid, errorMessage] = checkValidity(
      event.target.value,
      controls[inputId].validation
    );
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({ id: key, ...controls[key] });
  }

  let form = customControls(controls, inputValueChangedHandler);

  //#endregion

  //#region Actions
  const getItem = () => {
    let secciones = [];

    items.forEach((item) => {
      secciones.push({
        id: item.id,
        aplicacionId: info ? info.id : 0,
        nombre: item.nombre,
        aMedida: item.aMedida,
        consultor: item.consultor,
        seccionesClientes: item.seccionesClientes,
      });
    });

    const item = {
      id: info ? info.id : 0,
      nombre: controls.nombreItem.value,
      secciones: secciones,
    };

    return item;
  }

  const onSuccess = (data) => {
    onEditOrCancelItemClick();

    if(nuevo){
      close();
      onSuccessUpdateOrCreate(null);
    }else{
      onSuccessUpdateOrCreate(data);
    }     
    
    setTimeout(() => setLoadingItem(false), 500);
  }

  const onError = (error) => {
    console.log(error);
    setTimeout(() => setLoadingItem(false), 500);
  }

  const submitHandler = (event) => {
    if(formIsValid){
      const item = getItem();
      setLoadingItem(true);
      props.onCreateItem(item, onSuccess, onError);
    }
  }

  const onEditOrCancelItemClick = (restartValues) => {
    const updatedControls = { ...controls };

    for (let key in controls) {
      updatedControls[key].disabled = !controls[key].disabled;
    }

    if (restartValues) {
      updatedControls.nombreItem.value = info.nombre;
      updatedControls.nombreItem.valid = true;
    }

    setFormIsValid(true);
    setControls(updatedControls);
    setOnEdit(!onEdit);
  };

  const onGuardarCambiosClick = () => {
    if(formIsValid){
      const item = getItem();
      setLoadingItem(true);
      props.onUpdateItem(item, onSuccess, onError);
    }
  }

  const onEliminarSuccess = () => {
    setTimeout(() => setLoadingItem(false), 500);
    onEditOrCancelItemClick();
    onSuccessUpdateOrCreate(null);
  }

  const onEliminarClick = () => {
    const item = getItem();
    setLoadingItem(true);
    props.onDeleteItem(item.id, onEliminarSuccess, onError);
  }

  const onCrearItem = (item) => {
    setLoadingItem(true);
    if(item !== undefined){
      const tempItems = [...items, item];
      setItems(tempItems);
    }
    setTimeout(() => setLoadingItem(false), 500);
  }

  const onEditarItem = (item, index) => {
    setLoadingItem(true);
    if(item !== undefined && index !== undefined){      
      items[index].aMedida = item.aMedida;
      items[index].consultor = item.consultor;
      items[index].nombre = item.nombre;
      items[index].aplicacionId = item.aplicacionId;
      items[index].seccionesClientes = item.seccionesClientes;
    }
    setTimeout(() => setLoadingItem(false), 500);
  }

  const onEliminarItem = (index) => {
    setLoadingItem(true);
    if(index !== undefined){
      let tempItems = items.splice(index, 1);
      setItems(tempItems);
    }
    setTimeout(() => setLoadingItem(false), 500);
  }

  //#endregion

  return (
    <CardItems
      title={
        <div>
          <form>
            <div className={classes.titleCard} style={{ marginBottom: '20px' }}>
              <div style={{ width: '50%' }}>
                {form[0]}
              </div>
              <EditActions 
                info={info}
                formIsValid={formIsValid}
                inEdit={onEdit}
                submitHandler={submitHandler}
                onEditOrCancelClick={onEditOrCancelItemClick}
                onGuardarCambiosClick={onGuardarCambiosClick}
                onEliminarClick={onEliminarClick}
                itemTooltip="aplicación"
              />
            </div>
            <div className={classes.titleCard} style={{ marginTop: '20px' }}>
              <Typography align="left" style={{ color: "#00BCD4", fontSize: '16px' }}>
                Secciones
              </Typography>
              <div className={classes.titleCard}>
                {items.length === 0 
                  ? <AddActions nuevoItem={nuevoItem} setNuevoItem={setNuevoItem} />
                  : <NewActions nuevoItem={nuevoItem} setNuevoItem={setNuevoItem} /> 
                }              
              </div>
            </div>
          </form>
        </div>
      }
      body={
        <div className={classes.cardsItems}>
          {nuevoItem && (
            <CardSeccion 
              nuevo
              aplicacionId={info ? info.id : 0}
              crear={onCrearItem}
              close={(e) => setNuevoItem(e)} 
            />
          )}
          {items.length === 0 && !nuevoItem ? (
            <Typography align="center">
              No se encontraron secciones
            </Typography>
          ) : loadingItem ? (
            <div className={classes.spinnerItems}>
              <Spinner />
            </div>
          ) : (
            items.map((item, index) => (
              <CardSeccion 
                key={index}
                index={index}
                info={item}
                aplicacionId={info ? info.id : 0}
                crear={onCrearItem}
                editar={onEditarItem}
                eliminar={onEliminarItem} 
              />
            ))
          )}
        </div>
      }
    />
  )
}

const mapStateToProps = (state) => ({
  items: state.permisos.loadSecciones.items,
  loading: state.permisos.loadSecciones.loading,
  estaCargado: state.permisos.loadSecciones.estaCargado,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadItems: (idAplicacion) => dispatch(actions.loadSecciones(idAplicacion)),
    onCreateItem: (item, success, error) => dispatch(actions.crearAplicacion(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarAplicacion(item, success, error)),
    onDeleteItem: (id, success, error) => dispatch(actions.eliminarAplicacion(id, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardAplicacion);