import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "../Styles/Permisos.css";
import { getCheckboxConfig, customControls } from "../Common/Controls";
import Button from "../../UI/Button/Button";

const CardPerfilClienteAplicacion = (props) => {
  const {
    nuevo,
    items,
    inEdit,
    setInEdit,
  } = props;

  let {
    info,
  } = props;

  const [inEditItem, setInEditItem] = useState(false);
  const [controls, setControls] = useState({}); 
  const [controlsChecks, setControlsChecks] = useState({});
  const [controlAllChecks, setControlAllChecks] = useState({});

  const updateControlsAllChecks = useCallback((value = false) => {
    let tempControls = {
      all: getCheckboxConfig("allCheckbox", "Seleccionar todas las secciones", value, inEdit && info && info.incluido ? false : true, { width: '80%' }),
    };

    setControlAllChecks(tempControls);
    formAllChecks = customControls(tempControls, checkboxAllValueChangedHandler);
  }, []);

  const updateControlsChecks = useCallback((secciones, editar = false) => {
    let tempControls = {};
    let allValue = true;

    secciones.forEach(seccion => {
      const nombre = seccion.nombre.trim().replace(/ /g, '');
      const inputId = `${nombre}Checkbox`;
      const infoSeccion = info ? info.secciones.find((sec) => sec.id === seccion.id) : undefined;
      const value = infoSeccion ? true : false;
      allValue = allValue && value;

      tempControls = {
        ...tempControls,
        [inputId]: {
          elementType: 'checkbox',
          name: `${nombre}Check`,
          label: seccion.nombre,
          elementConfig: {
            type: "checkbox",
          },
          validation: {},
          required: true,
          disabled: editar && info && info.incluido ? false : true,
          value: value,
          valid: true,
          touched: false,
          errorMessage: '',
          seccionId: seccion.id,
        },
      };
    });

    setControlsChecks(tempControls);
    formChecks = customControls(tempControls, checkboxValueChangedHandler);

    updateControlsAllChecks(allValue);
  }, []);

  const updateControls = useCallback((info, aplicaciones, editar = false) => {
    const aplicacion = info ? aplicaciones.find((app) => app.id === info.id) : undefined;
    const value = aplicacion ? { id: aplicacion.id, nombre: aplicacion.nombre } : { id: '', nombre: '' };
    const control = {
      nombre: {
        elementType: "input",
        name: "nombreAplicacion",
        elementConfig: {
          type: "text",
        },
        validation: {},
        disabled: !editar,
        value: value.nombre,
        valid: true,
        touched: false,
        errorMessage: "",
        elementStyle: { width: '100%' }
      }
    }

    setControls(control);
    form = customControls(control, onChangeAplicacion);
  }, []);

  const changeAplicacion = useCallback((value, items, editar = false) => {
    let secciones = [];

    if(value !== null && value !== undefined){
      const item = items.find((item) => item.id === value.id);

      if(item !== undefined)
        secciones = item.secciones;
    }

    updateControlsChecks(secciones, editar);
  }, [updateControlsChecks]);

  const onChangeAplicacion = () => {}

  //#region Form
  const checkboxValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controlsChecks[inputId] };
    const value = event.target.checked;
    updatedControl.value = value;
    updatedControl.touched = true;

    const updatedControls = {
      ...controlsChecks,
      [inputId]: updatedControl,
    };

    setControlsChecks(updatedControls);

    if(value)
      info.secciones.push({ id: updatedControl.seccionId });      
    else
      info.secciones = info.secciones.filter((seccion) => seccion.id !== updatedControl.seccionId);
  };

  const checkboxAllValueChangedHandler = (event, inputId) => {
    const updatedAllControl = { ...controlAllChecks[inputId] };
    const value = event.target.checked;
    updatedAllControl.value = value;
    updatedAllControl.touched = true;

    const updatedAllControls = {
      ...controlAllChecks,
      [inputId]: updatedAllControl,
    };

    setControlAllChecks(updatedAllControls);
    
    let updatedControls = {};
    
    for(let inputCheckId in controlsChecks){
      const updatedControl = { ...controlsChecks[inputCheckId] };
      const value = event.target.checked;
      updatedControl.value = value;
      updatedControl.touched = true;

      updatedControls = {
        ...updatedControls,
        [inputCheckId]: updatedControl,
      };

      setControlsChecks(updatedControls);

      if(value)
        info.secciones.push({ id: updatedControl.seccionId });
    };

    if(!value)
      info.secciones = [];
  }

  let form = customControls(controls, onChangeAplicacion);
  let formAllChecks = customControls(controlAllChecks, checkboxAllValueChangedHandler);
  let formChecks = customControls(controlsChecks, checkboxValueChangedHandler);

  //#endregion

  //#region Actions

  const inEditChange = (edit) => {
    setInEditItem(edit);
    setInEdit(edit);
    const value = info !== null && info !== undefined
      ? { id: info.aplicacionId }
      : null;
    updateControls(value, items, edit);
    changeAplicacion(value, items, edit);
  }

  const onEditOrCancelItemClick = (restartValues, disabled) => {
    const updatedControlsChecks = { ...controlsChecks };

    for (let key in controlsChecks) {
      updatedControlsChecks[key].disabled = disabled;

      if(restartValues){
        updatedControlsChecks[key].valid = true;
        updatedControlsChecks[key].value = false;
      }
    }

    setControlsChecks(updatedControlsChecks);

    const updatedControlsAllChecks = { ...controlAllChecks };

    for (let key in controlAllChecks) {
      updatedControlsAllChecks[key].disabled = disabled;

      if(restartValues){
        updatedControlsAllChecks[key].valid = true;
        updatedControlsAllChecks[key].value = false;
      }
    }

    setControlAllChecks(updatedControlsAllChecks);
  };

  const incluir = () => {
    if(!inEditItem)
      inEditChange(true);

    onEditOrCancelItemClick(false, false);

    if(info === null || info === undefined){
      info = {
        incluido: true,
      }
    }else{
      info.incluido = true;
    }    
  }

  const quitar = () => {    
    if(!inEditItem)
      inEditChange(true);

    onEditOrCancelItemClick(true, true);

    if(info === null || info === undefined){
      info = {
        incluido: false,
      }
    }else{
      info.incluido = false;
    } 
  }

  const botonesIncluirQuitar = () => {
    return info && info.incluido
      ? (
        <Button
          onClick={() => quitar()}
          style={{
            color: 'white',
            textTransform: 'capitalize', 
            backgroundColor: '#E45164',  
            width: '70px',
            height: '25px'                  
          }}
          disabled={!nuevo && !inEdit}
        >
          Quitar
        </Button>
      ) : (
        <Button 
          onClick={() => incluir()}            
          style={{
            color: 'white',
            textTransform: 'capitalize', 
            backgroundColor: '#00BCD4',  
            width: '70px',
            height: '25px'                  
          }}
          disabled={!nuevo && !inEdit}
        >
          Incluir
        </Button>
      )
  }

  //#endregion

  useEffect(() => {
    const value = info !== null && info !== undefined
      ? { id: info.aplicacionId }
      : null;
    updateControls(value, items, inEdit);
    changeAplicacion(value, items, inEdit);
  }, [info, inEdit, items, changeAplicacion, updateControls]);

  useEffect(() => {
    if(inEdit !== inEditItem){
      const value = info !== null && info !== undefined
        ? { id: info.aplicacionId }
        : null;
      setInEditItem(inEdit);
      updateControls(value, items, inEdit);
      changeAplicacion(value, items, inEdit);
    }
  }, [info, items, inEdit, inEditItem, changeAplicacion, updateControls]);

  return (
    <div className={classes.cardContainer}>
        <div className={classes.titleCard} style={{ paddingLeft: '20px' }}>
          <div style={{ width: '45%' }}>
            {form[0]}
          </div>
          <div className={classes.actionIcons}>
            {botonesIncluirQuitar()}
          </div>
        </div>
        <Fragment>
          {formChecks.length !== 0 && (
            <Fragment>
              <div className={classes.rowFlex}>
                {formAllChecks[0]}
              </div>
              <div className={classes.rowFlex} style={{ flexWrap: 'wrap' }}>
                {formChecks.map((form, index) => (
                  <Fragment key={index}>
                    {form}
                  </Fragment>
                ))}
              </div>
            </Fragment>
          )}
        </Fragment>
    </div>
  )
}

const mapStateToProps = (state) => ({
  items: state.permisos.loadAplicaciones.items,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateItem: (item, success, error) => dispatch(actions.crearPlanesAccesos(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarPlanesAccesos(item, success, error)),
    onDeleteItem: (item, success, error) => dispatch(actions.eliminarPlanesAccesos(item, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPerfilClienteAplicacion);