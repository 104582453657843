import React, { useState, useEffect, useCallback, Fragment } from "react";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import classes from "../Styles/Permisos.css";
import { Typography } from "@material-ui/core";
import Spinner from "../../UI/Spinner/Spinner";
import { CardItems, NewActions, SelectControl } from "../Common";
import { CardPerfilCliente } from "..";
import { IconButton, Tooltip } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from '@material-ui/icons/Delete';

const CardPerfilesClientes = (props) => {
  const {
    loading,
    estaCargado,
    onLoadItems,
    onLoadAplicaciones,
    onDeleteItem,
  } = props;
  
  const [nuevoItem, setNuevoItem] = useState(false);
  const [item, setItem] = useState();
  const [loadingItem, setLoadingItem] = useState(false);
  const [inEdit, setInEdit] = useState(false);

  useEffect(() => {
    const clienteId = sessionStorage.getItem("clienteId");
    onLoadAplicaciones(clienteId);
  }, [onLoadAplicaciones]);

  useEffect(() => {
    if(!estaCargado)
      onLoadItems();
  }, [estaCargado, onLoadItems]);

  const onValueSelectChange = (item) => {
    setLoadingItem(true);
    setItem(item);
    setTimeout(() => setLoadingItem(false), 500);
  }

  const updateOnSuccess = useCallback((item) => {
    setLoadingItem(true);
    onLoadItems();
    setItem(item);
    setTimeout(() => setLoadingItem(false), 500);
  }, [onLoadItems, setItem, setLoadingItem]);  

  const onNuevoItemClick = () => {
    setItem(null);
    setInEdit(true);
    setNuevoItem(!nuevoItem);
  }

  const onEditOrCancelClick = () => {
    const tempItem = item;
    setLoadingItem(true);
    setItem(null);
    setInEdit(!inEdit);
    setTimeout(() => {
      setItem(tempItem);
      setLoadingItem(false);
    }, 500);
  }

  const onEliminarClick = () => {
    if(item){
      const onError = (error) => {
        console.log(error);
      }
    
      const onSuccess = () => {
        updateOnSuccess(null);
      }
            
      onDeleteItem(item, onSuccess, onError);
    }   
  }

  return (
    <CardItems
      title={
        <div>
          <div className={classes.titleCard}>
            <Typography align="left" style={{ color: "#00BCD4", fontSize: '18px' }}>
              Perfiles
            </Typography>
            {!item && (<NewActions nuevoItem={nuevoItem} setNuevoItem={onNuevoItemClick} title="Nuevo" />)}
            {item && !inEdit && (
              <div className={classes.actionIcons}>
                <Tooltip title={`Editar perfil`} placement="top">
                  <IconButton onClick={() => onEditOrCancelClick()}>
                    <EditIcon className={classes.actionIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={`Eliminar perfil`} placement="top">
                  <IconButton onClick={() => onEliminarClick()}>
                    <DeleteIcon className={classes.actionIcon} />
                  </IconButton>
                </Tooltip>
              </div>
            )}
          </div>
          <div className={classes.titleCard}>
            {loading ? (
              <div className={classes.spinnerItems}>
                <Spinner />
              </div>
            ) : !nuevoItem &&  (
              <SelectControl 
                dataSelectionId="perfilesCliente"
                onSelectChange={onValueSelectChange}
                selectedItem={item}
              />
            )}
          </div>
        </div>
      }
      body={
        <div className={classes.cardsItems}>
          {loadingItem ? (
            <div className={classes.spinnerItems}>
              <Spinner />
            </div>
          ) : (
            <Fragment>
              {nuevoItem && (
                <CardPerfilCliente 
                  nuevo 
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  close={() => setNuevoItem(!nuevoItem)} 
                  onSuccessUpdateOrCreate={updateOnSuccess}
                />
              )}
              {item &&  (
                <CardPerfilCliente 
                  info={item}
                  inEdit={inEdit}
                  setInEdit={setInEdit}
                  onSuccessUpdateOrCreate={updateOnSuccess}
                />
              )}
            </Fragment>
          )}
        </div>        
      }
    />
  );
}

const mapStateToProps = (state) => ({
  items: state.permisos.loadPerfilesCliente.items,
  loading: state.permisos.loadPerfilesCliente.loading,
  estaCargado: state.permisos.loadPerfilesCliente.estaCargado,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadItems: () => dispatch(actions.loadPerfilesCliente()),
    onLoadAplicaciones: (clienteId) => dispatch(actions.loadAplicaciones(clienteId)),
    onDeleteItem: (item, success, error) => dispatch(actions.eliminarPerfilCliente(item, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPerfilesClientes);