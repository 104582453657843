import React from "react";

import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      color: "white",
    },
  },
}));

const ButtonM = (props) => {
  const classes = useStyles();
  const color = props.color ? props.color : "primary";

  return (
    <div className={classes.root}>
      <Button
        style={props.style}
        type={props.submit ? "submit" : "button"}
        variant="contained"
        color={color}
        disabled={props.disabled || props.loading}
        onClick={props.onClick}
        size={props.size}
      >
        {props.loading ? <CircularProgress color={color} /> : props.children}
      </Button>
    </div>
  );
};

export default ButtonM;
