import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "../Styles/Permisos.css";
import { Typography } from "@material-ui/core";
import { CardItems, AddActions, NewActions, EditActions } from "../Common";
import { checkValidity } from "../../../assets/shared/utility";
import CardPlanAplicacion from "./CardPlanAplicacion";
import Spinner from "../../UI/Spinner/Spinner";
import { customControls } from "../Common/Controls";

const CardPlan = (props) => {
  const {
    nuevo,
    info,
    close,
    onSuccessUpdateOrCreate,
  } = props;

  const [items, setItems] = useState();
  const [nuevoItem, setNuevoItem] = useState(false);
  const [onEdit, setOnEdit] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [planesAccesos, setPlanesAccesos] = useState([]);

  const cargarItems = useCallback((planesAccesos) => {
    const tempItems = [];

    props.aplicaciones.forEach((aplicacion) => {
      const planesFiltrados = planesAccesos.filter((plan) => plan.aplicacionId === aplicacion.id);

      if(planesFiltrados !== undefined && planesFiltrados.length !== 0)
      {
        const item = {
          planId: info ? info.id : 0,
          aplicacionId: aplicacion.id,
          secciones: planesFiltrados.map((planFiltrado) => { return { id: planFiltrado.seccionId } })
        }

        tempItems.push(item);
      }
    });
    setItems(tempItems);
  }, [info, props.aplicaciones]);

  useEffect(() => {
    if(!nuevo && info !== undefined && items === undefined){
      cargarItems(info.planesAccesos);
      setPlanesAccesos(info.planesAccesos);
    }
  }, [nuevo, items, info, cargarItems]);

  //#region Form

  const [controls, setControls] = useState({
    nombreItem: {
      elementType: "input",
      name: "nombrePlane",
      elementConfig: {
        type: "text",
        placeholder: "Nombre plan",
      },
      validation: {
        required: true,
      },
      disabled: info ? true : false,
      value: info ? info.nombre : "",
      valid: true,
      touched: false,
      errorMessage: "",
      elementStyle: { width: '100%' }
    },
    planFullCheck: {
      elementType: "checkbox",
      name: "planFullCheck",
      label: "Plan full",
      elementConfig: {
        type: "checkbox",
      },
      validation: {},
      required: true,
      disabled: info ? true : false,
      value: info ? info.full : false,
      valid: true,
      touched: false,
      errorMessage: "",
    },
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    updatedControl.value = updatedControl.elementType === "checkbox" ? event.target.checked : event.target.value;
    const [isValid, errorMessage] = checkValidity(
      event.target.value,
      controls[inputId].validation
    );
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  let form = customControls(controls, inputValueChangedHandler);

  //#endregion

  //#region Actions
  const getItem = () => {
    const item = {
      id: info ? info.id : 0,
      nombre: controls.nombreItem.value,
      full: controls.planFullCheck.value,
      planesAccesos: planesAccesos,
    };

    return item;
  }

  const onSuccess = (data) => {
    onEditOrCancelItemClick();

    if(nuevo){
      close();
      onSuccessUpdateOrCreate(null);
    }else{
      onSuccessUpdateOrCreate(data);
    }

    setTimeout(() => setLoadingItem(false), 500);      
  }

  const onError = (error) => {
    console.log(error);
    setTimeout(() => setLoadingItem(false), 500);
  }

  const onEliminarSuccess = () => {
    setTimeout(() => setLoadingItem(false), 500);
    onEditOrCancelItemClick();
    onSuccessUpdateOrCreate(null);
  }

  const submitHandler = (event) => {
    if(formIsValid){
      setLoadingItem(true);
      const item = getItem();
      props.onCreateItem(item, onSuccess, onError);
    }
  }

  const onEditOrCancelItemClick = (restartValues) => {
    const updatedControls = { ...controls };

    for (let key in controls) {
      updatedControls[key].disabled = !controls[key].disabled;
    }

    if (restartValues) {
      updatedControls.nombreItem.value = info.nombre;
      updatedControls.nombreItem.valid = true;
      updatedControls.planFullCheck.value = info.full;
      updatedControls.planFullCheck.valid = true;
    }

    setFormIsValid(true);
    setControls(updatedControls);
    setOnEdit(!onEdit);
  };
  
  const onGuardarCambiosClick = () => {
    if(formIsValid){
      setLoadingItem(true);
      const item = getItem();
      props.onUpdateItem(item, onSuccess, onError);
    }
  }

  const onEliminarClick = () => {
    setLoadingItem(true);
    const item = getItem();
    props.onDeleteItem(item.id, onEliminarSuccess, onError);
  }

  const onCrearItem = (planesAccesosParam) => {
    setLoadingItem(true);
    if(planesAccesosParam !== undefined){  
      let tempPlanes = [...planesAccesos, ...planesAccesosParam];
      cargarItems(tempPlanes);
      setPlanesAccesos(tempPlanes);
    }
    setTimeout(() => setLoadingItem(false), 500);
  }

  const onEditarItem = (planesAccesosParam, index) => {
    setLoadingItem(true);    

    if(planesAccesosParam !== undefined && planesAccesosParam.length !== 0){
      let filterPlanes = planesAccesos.filter((planAcc) => planAcc.planId === planesAccesosParam[0].planId && planAcc.aplicacionId !== planesAccesosParam[0].aplicacionId);
      let tempPlanes = filterPlanes;

      planesAccesosParam.forEach((acceso) => { tempPlanes.push(acceso); })
            
      cargarItems(tempPlanes);
      setPlanesAccesos(tempPlanes);
    }

    setTimeout(() => setLoadingItem(false), 500);
  }

  const onEliminarItem = (planesAccesosParam, index) => {
    setLoadingItem(true);

    let tempPlanes = [];

    if(planesAccesosParam !== undefined && planesAccesosParam.length !== 0){
      
      planesAccesos.forEach((p1) => {
        const tempPlan = planesAccesosParam.find((p2) => p2.planId === p1.planId && p2.aplicacionId === p1.aplicacionId && p2.seccionId === p1.seccionId);

        if(tempPlan === null || tempPlan === undefined){
          tempPlanes.push(p1);
        }
      });      
    }else{
      tempPlanes = planesAccesos;
      tempPlanes.splice(index, 1);
    }

    cargarItems(tempPlanes);
    setPlanesAccesos(tempPlanes);
    
    setTimeout(() => setLoadingItem(false), 500);
  }

  //#endregion

  return (
    <CardItems
      title={
        <div>
          <form>
            <div className={classes.titleCard} style={{ marginBottom: '20px' }}>
              <div style={{ width: '50%' }}>
                {form[0]}
              </div>              
              <EditActions 
                info={info}
                formIsValid={formIsValid}
                inEdit={onEdit}
                submitHandler={submitHandler}
                onEditOrCancelClick={onEditOrCancelItemClick}
                onGuardarCambiosClick={onGuardarCambiosClick}
                onEliminarClick={onEliminarClick}
                itemTooltip="plan"
              />
            </div>
            <div className={classes.titleCard}>
                {form[1]}
              </div>
            <div className={classes.titleCard} style={{ marginTop: '20px' }}>              
              <Typography align="left" style={{ color: "#00BCD4", fontSize: '16px' }}>
                Aplicaciones
              </Typography>
              <div className={classes.titleCard}>
                {items && items.length !== 0 
                  ? <NewActions nuevoItem={nuevoItem} setNuevoItem={setNuevoItem} />
                  : <AddActions nuevoItem={nuevoItem} setNuevoItem={setNuevoItem} />  
                }              
              </div>
            </div>
          </form>
        </div>
      }
      body={
        <div className={classes.cardsItems}>
          {nuevoItem && (
            <CardPlanAplicacion 
              nuevo
              planId={info ? info.id : 0} 
              crear={onCrearItem}
              editar={onEditarItem}
              close={(e) => setNuevoItem(e)} 
            />
          )}
          {(items === undefined || items.length === 0) && !nuevoItem ? (
            <Typography align="center">
              No se encontraron aplicaciones
            </Typography>
          ) : loadingItem ? (
            <div className={classes.spinnerItems}>
              <Spinner />
            </div>
          ) : (
            items && items.map((item, index) => (
              <CardPlanAplicacion 
                key={index} 
                index={index}
                info={item} 
                planId={info ? info.id : 0} 
                crear={onCrearItem}
                editar={onEditarItem} 
                eliminar={onEliminarItem} 
              />
            ))
          )}
        </div>
      }
    />
  )
}

const mapStateToProps = (state) => {
  return {    
    aplicaciones: state.permisos.loadAplicaciones.items,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateItem: (item, success, error) => dispatch(actions.crearPlan(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarPlan(item, success, error)),
    onDeleteItem: (id, success, error) => dispatch(actions.eliminarPlan(id, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPlan);