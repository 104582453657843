import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  indicadoresDelUsuario: {
    loading: false,
    items: [],
    error: null,
  },
};

//#region Load Indicadores
const loadIndicadoresStart = (state) => {
  return updateObject(state, {
    indicadoresDelUsuario: updateObject(state.indicadoresDelUsuario, {
      loading: true,
      items: [],
      error: null,
    }),
  });
};

const loadIndicadoresSuccess = (state, indicadores) => {
  return updateObject(state, {
    indicadoresDelUsuario: updateObject(state.indicadoresDelUsuario, {
      items: indicadores,
      loading: false,
    }),
  });
};

const loadIndicadoresFail = (state, error) => {
  return updateObject(state, {
    indicadoresDelUsuario: updateObject(state.indicadoresDelUsuario, {
      loading: false,
      error,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_INDICADORES_DEL_USUARIO_START:
      return loadIndicadoresStart(state);
    case actionTypes.LOAD_INDICADORES_DEL_USUARIO_SUCCESS:
      return loadIndicadoresSuccess(state, action.indicadores);
    case actionTypes.LOAD_INDICADORES_DEL_USUARIO_FAIL:
      return loadIndicadoresFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;