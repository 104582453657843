import * as actionTypes from "./actionTypes";
import axios from "../../axios-instance";

export const loadClientes = () => {
  return (dispatch) => {
    dispatch(loadClientesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/SGC/clientes`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadClientesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadClientesFail(error));
      });
  };
};

const loadClientesStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTES_START,
  };
};

const loadClientesSuccess = (clientes) => {
  return {
    type: actionTypes.LOAD_CLIENTES_SUCCESS,
    clientes,
  };
};

const loadClientesFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTES_FAIL,
    error,
  };
};

export const clientSelected = (client) => {
  return {
    type: actionTypes.CLIENT_SELECTED,
    client,
  };
};

export const crearCliente = (nuevoCliente, index, success) => {
  return (dispatch) => {
    dispatch(executeCrearCliente(true));
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .post(`/clientes`, nuevoCliente, {
        headers: { Authorization: authStr, "Content-Type": "application/json" },
      })
      .then((response) => {
        dispatch(actualizarClienteCreado(response.data, index));
        dispatch(loadClientes());
        dispatch(executeCrearCliente(false));
        return success(response);
      })
      .catch((error) => {
        dispatch(executeCrearCliente(false));
      });
  };
};

const executeCrearCliente = (loading) => {
  return {
    type: actionTypes.EXECUTE_CREATE_CLIENTE,
    loading,
  };
};

const actualizarClienteCreado = (clienteCreado, index) => {
  return {
    type: actionTypes.ACTUALIZAR_CLIENTE_CREADO,
    clienteCreado,
    index,
  };
};

export const modificarCliente = (
  clienteModificado,
  index,
  clienteId,
  success
) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .put(`/clientes?id=${clienteId}`, clienteModificado, {
        headers: { Authorization: authStr, "Content-Type": "application/json" },
      })
      .then((response) => {
        dispatch(actualizarClienteCreado(response.data, index));
        dispatch(loadClientes());
        return success();
      })
      .catch((error) => {});
  };
};

export const loadCliente = (clienteId) => {
  return (dispatch) => {
    dispatch(loadClienteStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/clientes/${clienteId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadClienteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadClienteFail(error));
      });
  };
};

const loadClienteStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTE_START,
  };
};

const loadClienteSuccess = (cliente) => {
  return {
    type: actionTypes.LOAD_CLIENTE_SUCCESS,
    cliente,
  };
};

const loadClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTE_FAIL,
    error,
  };
};