import React, { useState, useEffect } from "react";
import * as actions from "../../../store/actions";

import Table from "../../UI/Table/Table";

import { connect } from "react-redux";
import VendedorSelector from "../../UI/VendedorSelector/VendedorSelector";

const TableVendedoresUserConfig = (props) => {
  const tieneAcceso = true;

  const {
    empresas,
    loading,
    onLoadEmpresas,
    userId,
    onChangeVendedorAsigado,
  } = props;

  useEffect(() => {
    onLoadEmpresas(userId);
  }, [onLoadEmpresas, userId]);

  const cellStyles = {    
    padding:0,
    height:42, 
    fontSize: '14px',
    paddingBottom: '0px',
    paddingTop: '0px',
    paddingLeft: '5px',
    paddingRight: '5px'
  };

  const headerStyles = {
    fontSize: 'small',
    paddingTop: '0px',
    paddingBottom: '0px',
    backgroundColor: 'white',
    paddingLeft: '5px',
    paddingRight: '5px',
  };

  const [columns] = useState([
    {
      title: "Empresa",
      field: "empresa",
      editable: "never",
      headerStyle: headerStyles,
      cellStyle: cellStyles
    },
    {
      title: "Vendedor",
      field: "nombreVendedor",
      editComponent: (props) => {
        return (
          <VendedorSelector
            dsn={props.rowData.dsn}
            vendedorAsignado={props.rowData.idVendedor}
            onChangeVendedor={(vendId) => {
              var data = { ...props.rowData };
              data.idVendedor = vendId;
              props.onRowDataChange(data);
            }}
          />
        );
      },
      headerStyle: headerStyles,
      cellStyle: cellStyles
    },
  ]);

  const data = () => {
    return empresas.map((empresa) => ({
      empresa: empresa.nombreEmpresa,
      id: empresa.idEmpresa,
      dsn: empresa.dsn,
      idVendedor: empresa.idVendedor,
      nombreVendedor: empresa.nombreVendedor,
    }));
  };

  return (
    <Table
      title={props.userEmail}
      data={tieneAcceso ? data() : []}
      loading={loading}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            onChangeVendedorAsigado(newData, userId, (success) => {
              onLoadEmpresas(userId);
              resolve();
            });
          }),
      }}
      columns={columns}
      customOptions={{
        actionsColumnIndex: -1,
        grouping: false,
        draggable: false,
        columnResizable: false,
        tableLayout: "auto",
        paging: false,
        search: false,
        sorting: false,
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.configuraciones.dashboardUserConfig.userId,
    userEmail: state.configuraciones.dashboardUserConfig.userEmail,
    empresas: state.empresas.empresas.empresas,
    loading: state.empresas.empresas.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadEmpresas: (idUser) => dispatch(actions.loadEmpresas(idUser)),
    onChangeVendedorAsigado: (data, idUser, success) =>
      dispatch(actions.cambiarVendedorAsignado(data, idUser, success)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableVendedoresUserConfig);
