import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import Spinner from "../../UI/Spinner/Spinner";
import { FormControl, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex", 
    alignItems: "center",
    width: "45%"
  },
  rootControl:{
    margin: "0px 10px 0px 10px", 
    color: "black",
    width: "100%"
  },
  autocomplete: {
    width: 'auto',
    textAlign: 'start',
    fontSize: 'small',   
    minWidth: '250px',
  },
  input: {
    fontSize: "small",
  },
  options:{
    fontSize: 'small',
  },
}));

const SelectControl = (props) => {
  const { 
    dataSelectionId,
    onSelectChange,
    aplicaciones,
    planes, perfiles,
    estaCargadoAplicaciones,
    estaCargadoPerfiles, 
    estaCargadoPlanes,
  } = props;

  const classes = useStyles();

  const [selectedItem, setSelectedItem] = useState(null);
  const [items, setItems] = useState([]);

  const dataOptions = {
    aplicaciones: {
      id: 0,
      stringId: 'aplicaciones',
      description: 'SELECCIONE APLICACIÓN',
      loading: 'loadingAplicaciones',
      items: 'aplicaciones',
      estaCargado: 'estaCargadoAplicaciones',
    },
    planes: {
      id: 1,
      stringId: 'planes',
      description: 'SELECCIONE PLAN',
      loading: 'loadingPlanes',
      items: 'planes',
      estaCargado: 'estaCargadoPlanes',
    },
    perfiles: {
      id: 2,
      stringId: 'perfiles',
      description: 'SELECCIONE PERFIL',
      loading: 'loadingPerfiles',
      items: 'perfiles',
      estaCargado: 'estaCargadoPerfiles',
    },
    perfilesCliente: {
      id: 3,
      stringId: 'perfilesCliente',
      description: 'SELECCIONE PERFIL',
      loading: 'loadingPerfilesCliente',
      items: 'perfilesCliente',
      estaCargado: 'estaCargadoPerfilesCliente',
    },
  };

  const onSelectValueChange = (event, item) => {
    if(item !== undefined){
      setSelectedItem(item);
      onSelectChange(item);
    }
  }

  useEffect(() => {
    if (!props[dataOptions[dataSelectionId].estaCargado]) {
      setItems(props[dataOptions[dataSelectionId].items]);
    }
  }, [dataSelectionId, aplicaciones, perfiles, planes, estaCargadoAplicaciones, estaCargadoPerfiles, 
    estaCargadoPlanes, props, dataOptions]);

  useEffect(() => {
    if(props.selectedItem !== undefined && props.selectedItem !== selectedItem){
      setSelectedItem(props.selectedItem);
    }
  }, [props.selectedItem, selectedItem, setSelectedItem]);

  const description = dataOptions[dataSelectionId].description;
  const stringId = dataOptions[dataSelectionId].stringId;

  return props[dataOptions[dataSelectionId].loading] ? (
    <Spinner personalSize="35px" />
  ) : (
    <div className={classes.root}>
      <FormControl className={classes.rootControl} >
        <Autocomplete       
          id={`${stringId}Select`} 
          classes={{
            root: classes.autocomplete,
            input: classes.input,
            option: classes.options,
          }}
          size="small"
          autoComplete={true}
          options={items}
          getOptionLabel={(option) => option.nombre ? option.nombre.toUpperCase().trimEnd() : "" }
          renderInput={(params) => <TextField {...params} className={classes.input} placeholder={description} margin="normal" />}
          value={selectedItem}
          onChange={(event, newValue) => onSelectValueChange(event, newValue)}
        />
      </FormControl>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loadingAplicaciones: state.permisos.loadAplicaciones.loading,
    aplicaciones: state.permisos.loadAplicaciones.items,
    estaCargadoAplicaciones: state.permisos.loadAplicaciones.estaCargado,
    
    loadingPerfiles: state.permisos.loadPerfiles.loading,
    perfiles: state.permisos.loadPerfiles.items,
    estaCargadoPerfiles: state.permisos.loadPerfiles.estaCargado,

    loadingPlanes: state.permisos.loadPlanes.loading,
    planes: state.permisos.loadPlanes.items,
    estaCargadoPlanes: state.permisos.loadPlanes.estaCargado,

    loadingPerfilesCliente: state.permisos.loadPerfilesCliente.loading,
    perfilesCliente: state.permisos.loadPerfilesCliente.items,
    estaCargadoPerfilesCliente: state.permisos.loadPerfilesCliente.estaCargado,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectControl);