import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  token: null,
  error: null,
  loading: false,
  authRedirectPath: "/fotoDelDia",
  usuariosPorCliente: {
    usuarios: [],
    loading: false,
    error: null,
  },
  userInfo: {},
  recuperarPassword: {
    loading: false,
    error: null,
    success: false,
  },
  cambiarPassword: {
    loading: false,
    error: null,
    success: false,
  }
};

const authStart = (state) => {
  return updateObject(state, {
    token: null,
    error: null,
    loading: true,
    authRedirectPath: "/fotoDelDia",
  });
};

const authSuccess = (state, token) => {
  return updateObject(state, {
    error: null,
    loading: false,
    token: token,
  });
};

const authFail = (state, error) => {
  return updateObject(state, { loading: false, error: error });
};

const authLogout = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    token: null,
    authRedirectPath: "/",
  });
};

const setAuthRedirectPath = (state, path) => {
  return updateObject(state, {
    authRedirectPath: path,
  });
};

const eliminarToken = (state) => {
  return updateObject(state, {
    token: null,
  });
};

const loadUsuariosPorClienteStart = (state) => {
  return updateObject(state, {
    usuariosPorCliente: updateObject(state.usuariosPorCliente, {
      loading: true,
    }),
  });
};

const loadUsuariosPorClienteSuccess = (state, usuarios) => {
  return updateObject(state, {
    usuariosPorCliente: updateObject(state.usuariosPorCliente, {
      loading: false,
      usuarios,
    }),
  });
};

const loadUsuariosPorClienteFail = (state, error) => {
  return updateObject(state, {
    usuariosPorCliente: updateObject(state.usuariosPorCliente, {
      error,
      loading: false,
      usuarios: [],
    }),
  });
};

const clearUsuariosPorCliente = (state) => {
  return updateObject(state, {
    usuariosPorCliente: updateObject(state.usuariosPorCliente, {
      error: null, 
      loading: false, 
      usuarios: []
    })
  })
}
//#region Recuperar Password
const recuperarPasswordStart = (state) => {
  return updateObject(state, {
    recuperarPassword: updateObject(state.recuperarPassword, {
      error: null,
      loading: true,
    }),
  });
};

const recuperarPasswordSuccess = (state) => {
  return updateObject(state, {
    recuperarPassword: updateObject(state.recuperarPassword, {
      error: null,
      loading: false,
      success: true,
    }),
  });
};

const recuperarPasswordFail = (state, error) => {
  return updateObject(state, { 
    recuperarPassword: updateObject(state.recuperarPassword, {
      loading: false, 
      error: error,
      success: false,
    }),
  });
};

const cleanRecuperarPassword = (state) => {
  return updateObject(state, { 
    recuperarPassword: updateObject(state.recuperarPassword, {
      loading: false, 
      success: false,
      error: null,
    }),
  });
};
//#endregion

//#region Cambiar Password
const cambiarPasswordStart = (state) => {
  return updateObject(state, {
    cambiarPassword: updateObject(state.cambiarPassword, {
      error: null,
      loading: true,
    }),
  });
};

const cambiarPasswordSuccess = (state) => {
  return updateObject(state, {
    cambiarPassword: updateObject(state.cambiarPassword, {
      error: null,
      loading: false,
      success: true,
    }),
  });
};

const cambiarPasswordFail = (state, error) => {
  return updateObject(state, { 
    cambiarPassword: updateObject(state.cambiarPassword, {
      loading: false, 
      success: false,
      error: error,
    }),
  });
};

const cleanCambiarPassword = (state) => {
  return updateObject(state, {
    cambiarPassword: updateObject(state.cambiarPassword, {
      error: null,
      success: false,
      loading: false,
    }),
  });
};
//#endregion

const setUserInfo = (state, userInfo) => {
  return updateObject(state, {
    userInfo: updateObject(state.userInfo, userInfo),
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action.token);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action.error);

    case actionTypes.SET_USER_INFO:
      return setUserInfo(state, action.user);

    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);

    case actionTypes.AUTH_SET_REDIRECT_PATH:
      return setAuthRedirectPath(state, action.path);

    case actionTypes.ELIMINAR_TOKEN:
      return eliminarToken(state);

    case actionTypes.LOAD_USUARIOS_POR_CLIENTE_START:
      return loadUsuariosPorClienteStart(state);
    case actionTypes.LOAD_USUARIOS_POR_CLIENTE_SUCCESS:
      return loadUsuariosPorClienteSuccess(state, action.usuarios);
    case actionTypes.LOAD_USUARIOS_POR_CLIENTE_FAIL:
      return loadUsuariosPorClienteFail(state, action.error);
    case actionTypes.CLEAR_USUARIOS_POR_CLIENTE:
      return clearUsuariosPorCliente(state);

    case actionTypes.RECUPERAR_PASSWORD_START:
      return recuperarPasswordStart(state);
    case actionTypes.RECUPERAR_PASSWORD_SUCCESS:
      return recuperarPasswordSuccess(state);
    case actionTypes.RECUPERAR_PASSWORD_FAIL:
      return recuperarPasswordFail(state, action.error);
    case actionTypes.CLEAN_RECUPERAR_PASSWORD:
      return cleanRecuperarPassword(state);

    case actionTypes.CAMBIAR_PASSWORD_START:
      return cambiarPasswordStart(state);
    case actionTypes.CAMBIAR_PASSWORD_SUCCESS:
      return cambiarPasswordSuccess(state);
    case actionTypes.CAMBIAR_PASSWORD_FAIL:
      return cambiarPasswordFail(state, action.error);
    case actionTypes.CLEAN_CAMBIAR_PASSWORD:
      return cleanCambiarPassword(state);

    default:
      return state;
  }
};

export default reducer;
