import * as actionTypes from "./actionTypes";

export const openModalUserConfig = (userId, userEmail, appConfig) => {
  return {
    type: actionTypes.OPEN_MODAL_USER_CONFIG,
    userId,
    userEmail,
    appConfig,
  };
};

export const closeModalUserConfig = () => {
  return {
    type: actionTypes.CLOSE_MODAL_USER_CONFIG,
  };
};
