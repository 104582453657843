import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { checkValidity } from "../../../assets/shared/utility";
import classes from "../Styles/Permisos.css";
import { customControls } from '../Common/Controls';
import { EditActions } from "../Common";
import Spinner from "../../UI/Spinner/Spinner";

const CardPlanAplicacion = (props) => {
  const {
    nuevo,
    info,
    index,
    planId,
    close,
    items,
    crear,
    editar,
    eliminar,
  } = props;

  const [onEdit, setOnEdit] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [aplicacion, setAplicacion] = useState(null);
  const [controls, setControls] = useState({}); 
  const [controlsChecks, setControlsChecks] = useState({}); 

  const updateControlsChecks = useCallback((secciones) => {
    let tempControls = {};

    secciones.forEach(seccion => {
      const nombre = seccion.nombre.trim().replace(/ /g, '');
      const inputId = `${nombre}Checkbox`;
      const infoSeccion = info ? info.secciones.find((sec) => sec.id === seccion.id) : undefined;

      tempControls = {
        ...tempControls,
        [inputId]: {
          elementType: 'checkbox',
          name: `${nombre}Check`,
          label: seccion.nombre,
          elementConfig: {
            type: "checkbox",
          },
          validation: {},
          required: true,
          disabled: info ? true : false,
          value: infoSeccion ? true : false,
          valid: true,
          touched: false,
          errorMessage: '',
        },
      };
    });

    setControlsChecks(tempControls);
    formChecks = customControls(tempControls, checkboxValueChangedHandler);
  }, []);

  const updateControls = useCallback((info, aplicaciones) => {
    const aplicacion = info ? aplicaciones.find((app) => app.id === info.id) : undefined;
    const value = aplicacion ? { id: aplicacion.id, nombre: aplicacion.nombre } : { id: '', nombre: '' };
    const control = {
      select: {
        elementType: "select",
        name: "aplicacionSelect",
        label: "Aplicación",
        items: aplicaciones.map((item) => { return { id: item.id, nombre: item.nombre }}),
        elementConfig: {
          type: "select",
        },
        validation: {},
        required: true,
        disabled: info ? true : false,
        value: value,
        valid: true,
        touched: false,
        errorMessage: "",
      },
    }

    setControls(control);
    form = customControls(control, onChangeAplicacion);
  }, []);

  const changeAplicacion = useCallback((value, items) => {
    let secciones = [];

    if(value !== null && value !== undefined){
      const item = items.find((item) => item.id === value.id);

      if(item !== undefined){
        setAplicacion(item);
        secciones = item.secciones;
      }       
    }

    updateControlsChecks(secciones);
  }, [updateControlsChecks]);

  const onChangeAplicacion = (newValue, inputId) => {
    setLoadingItem(true);    
    const inputItem = { target: { value: newValue }};
    inputValueChangedHandler(inputItem, inputId);
    changeAplicacion(newValue, items);    
    setTimeout(() => setLoadingItem(false), 500);
  }

  //#region Form
  const [formIsValid, setFormIsValid] = useState(false);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    const value = event.target.value;
    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, updatedControl.validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  const checkboxValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controlsChecks[inputId] };
    const value = event.target.checked;
    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, updatedControl.validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controlsChecks,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControlsChecks(updatedControls);
    setFormIsValid(formIsValid);
  };

  let form = customControls(controls, onChangeAplicacion);
  let formChecks = customControls(controlsChecks, checkboxValueChangedHandler);

  //#endregion

  //#region Actions

  const getItem = () => {  
    let items = [];

    if(aplicacion !== null && aplicacion !== undefined){
      aplicacion.secciones.forEach((seccion) => {
        const nombre = seccion.nombre.trim().replace(/ /g, '');
        const inputId = `${nombre}Checkbox`;
        const check = { ...controlsChecks[inputId] };

        if(check.value){
          let item = {
            planId: planId,
            aplicacionId: aplicacion.id,
            seccionId: seccion.id,
          };
  
          items.push(item);
        }
      });

      if(items.length === 0){
        let item = {
          planId: planId,
          aplicacionId: aplicacion.id,
          seccionId: 0,
        };

        items.push(item);
      }
    }

    return items;
  }

  const submitHandler = () => {
    let item = getItem();
    
    const onSuccess = (data) => {
      crear(data);
    }

    const onError = (error) => {
      console.log(error);
    }

    if(planId !== 0){
      props.onCreateItem(item, onSuccess, onError);
    }else{
      crear(item);
    }

    if(nuevo)
      close();
  }

  const onEditOrCancelItemClick = (restartValues) => {
    const updatedControls = { ...controls };

    for (let key in controls) {
      updatedControls[key].disabled = !controls[key].disabled;
    }

    if (restartValues) {
      const value = info !== null && info !== undefined
      ? { id: info.aplicacionId }
      : null;
      updatedControls.select.value = value;
      updatedControls.select.valid = true;
    }
    setControls(updatedControls);

    const updatedControlsChecks = { ...controlsChecks };

    for (let key in controlsChecks) {
      updatedControlsChecks[key].disabled = !controlsChecks[key].disabled;
    }
    setControlsChecks(updatedControlsChecks);

    setFormIsValid(true);
    setOnEdit(!onEdit);
  }; 

  const onGuardarCambiosClick = () => {
    let item = getItem();

    const onSuccess = (data) => {
      editar(data, index);
    }

    const onError = (error) => {
      console.log(error);
    }

    if(planId !== 0){
      props.onUpdateItem(item, onSuccess, onError);
    }else{
      editar(item, index);
    }    
  }

  const onEliminarClick = () => {
    const onSuccess = (success, data) => {
      if(success){
        eliminar(data, index);
      }
    }

    const onError = (error) => {
      console.log(error);
    }

    if(info !== undefined && info.planId !== undefined && info.planId !== 0){
      let item = getItem();
      props.onDeleteItem(item, onSuccess, onError);
    }else{
      eliminar(undefined, index);
    }
  }

  //#endregion  

  useEffect(() => {
    const value = info !== null && info !== undefined
      ? { id: info.aplicacionId }
      : null;
    updateControls(value, items);
    changeAplicacion(value, items);
  }, [info, changeAplicacion, items, updateControls]);

  return (
    <div className={classes.cardContainer}>
      <form>
        <div className={classes.titleCard} style={{ paddingLeft: '20px' }}>
          <div style={{ width: '45%' }}>
            {form[0]}
          </div>
          <EditActions 
            info={info}
            formIsValid={formIsValid}
            inEdit={onEdit}
            submitHandler={submitHandler}
            onEditOrCancelClick={onEditOrCancelItemClick}
            onGuardarCambiosClick={onGuardarCambiosClick}
            onEliminarClick={onEliminarClick}
            itemTooltip="plan de acceso"
          />
        </div>
        {loadingItem ? (
          <div className={classes.spinnerItems}>
            <Spinner />
          </div>
        ) : (
          <Fragment>
            {formChecks.length !== 0 && (
              <div className={classes.rowFlex} style={{ flexWrap: 'wrap' }}>
                {formChecks.map((form, index) => (
                  <Fragment key={index}>
                    {form}
                  </Fragment>
                ))}
                <div style={{ width: '25%' }}>
                  {form[1]}
                </div>
                <div style={{ width: '25%' }}>
                  {form[2]}
                </div>
              </div>
            )}
          </Fragment>
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  items: state.permisos.loadAplicaciones.items,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateItem: (item, success, error) => dispatch(actions.crearPlanesAccesos(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarPlanesAccesos(item, success, error)),
    onDeleteItem: (item, success, error) => dispatch(actions.eliminarPlanesAccesos(item, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPlanAplicacion);