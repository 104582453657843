import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  globalModal: {
    show: false,
    title: "",
    body: "",
    footer: "",
    disableCloseButton: false,
    fullWidth: false,
    maxWidth: "",
  },
};

const showGlobalModal = (
  state,
  title,
  body,
  footer,
  disableCloseButton,
  fullWidth,
  maxWidth
) => {
  return updateObject(state, {
    globalModal: updateObject(state.globalModal, {
      show: true,
      title,
      body,
      footer,
      disableCloseButton,
      fullWidth,
      maxWidth,
    }),
  });
};

const changeGlobalModalBody = (state, body) => {
  return updateObject(state, {
    globalModal: updateObject(state.globalModal, {
      body,
    }),
  });
};

const hiddenGlobalModal = (state) => {
  return updateObject(state, {
    globalModal: initialState.globalModal,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_GLOBAL_MODAL:
      return showGlobalModal(
        state,
        action.title,
        action.body,
        action.footer,
        action.disableCloseButton,
        action.fullWidth,
        action.maxWidth
      );
    case actionTypes.CHANGE_GLOBAL_MODAL_BODY:
      return changeGlobalModalBody(state, action.newBody);

    case actionTypes.HIDDEN_GLOBAL_MODAL:
      return hiddenGlobalModal(state);

    default:
      return state;
  }
};

export default reducer;
