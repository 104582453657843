import { urlPortalCambiarPassword } from "../../assets/shared/urlApps";
import axios from "../../axios-instance";
import * as actionTypes from "./actionTypes";
import { loadCliente } from "./clientes";
import { enqueueSnackbar } from './snackbar';

export const login = (email, password) => {
  return (dispatch) => {
    dispatch(authStart());

    axios
      .post("/usuarios/Login", {
        email: email.toLowerCase(),
        password: password,
      })
      .then((response) => {
        if (response.status !== 200) logout();

        if (response) {
          const user = response.data;
          fillUserInfo(user);
          dispatch(authSuccess(user.token));
          dispatch(setUserInfo(user));
          dispatch(checkAuthTimeout(24 * 60 * 60));
        }
      })
      .catch((error) => {
        if (error.response)
          dispatch(authFail(error.response.data));
        else
          dispatch(authFail("Se produjo un error conectando con el servidor"));
      });
  };
};

const fillUserInfo = (user) => {
  sessionStorage.setItem("token", user.token);
  sessionStorage.setItem("expirationDate", user.expiration);
  sessionStorage.setItem("userEmail", user.email);
  sessionStorage.setItem("clienteId", user.clienteId);
  sessionStorage.setItem("isAdmin", user.isAdmin);
  sessionStorage.setItem("tieneVendedor", user.tieneVendedor);
  sessionStorage.setItem("fechaFija", user.fechaFija);
  sessionStorage.setItem("gauss", user.gauss);
  sessionStorage.setItem("administracion", user.administracion);
  sessionStorage.setItem("userFullName", `${user.nombre} ${user.apellido}`);

  if (user.perfil !== null && user.perfil !== undefined) {
    sessionStorage.setItem("default", user.perfil.default);
    sessionStorage.setItem("consultor", user.perfil.consultor);
    sessionStorage.setItem("vendedor", user.perfil.vendedor);
    sessionStorage.setItem("administrador", user.perfil.administrador);
    sessionStorage.setItem("administradorAplicaciones", user.perfil.administradorAplicaciones);
    sessionStorage.setItem("gauss", user.perfil.gauss);
    sessionStorage.setItem("demo", user.perfil.demo);
    sessionStorage.setItem("cliente", user.perfil.cliente);
  }
}

const setUserInfo = (user) => {
  sessionStorage.setItem("userInfo", JSON.stringify(user));

  return {
    type: actionTypes.SET_USER_INFO,
    user,
  };
};

const checkToken = (token) => {
  return (dispatch) => {
    dispatch(authStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/usuarios/CheckLogin`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        if (response.status !== 200) logout();

        if (response) {
          const user = response.data;
          fillUserInfo(user);
          dispatch(authSuccess(user.token));
          dispatch(checkAuthTimeout(24 * 60 * 60));
        }
      })
      .catch((error) => {
        if (error.response) dispatch(authFail(error.response.data));
        else
          dispatch(authFail("Se produjo un error conectando con el servidor"));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = sessionStorage.getItem("token");

    if (!token) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(sessionStorage.getItem("expirationDate"));

      if (expirationDate > new Date()) {
        dispatch(checkToken(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      } else dispatch(logout());
    }
  };
};

const checkAuthTimeout = (expiresIn) => {
  return (dispatch) => {
    setTimeout(() => {
      console.log("[checkAuthTimeout]", "por logout por timer");
      dispatch(logout());
    }, expiresIn * 1000);
  };
};

export const logout = () => {
  sessionStorage.clear();
  return { type: actionTypes.AUTH_LOGOUT };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token, userEmail) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token,
    userEmail,
  };
};

const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const setAuthRedirectPath = (path) => {
  return {
    type: actionTypes.AUTH_SET_REDIRECT_PATH,
    error: path,
  };
};

export const eliminarToken = () => {
  return {
    type: actionTypes.ELIMINAR_TOKEN,
  };
};

export const loadUsuariosPorCliente = (
  clienteId = sessionStorage.getItem("clienteId")
) => {
  return (dispatch) => {
    dispatch(loadUsuariosPorClienteStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/usuarios/UsuariosPorCliente?clienteId=${clienteId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadUsuariosPorClienteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadUsuariosPorClienteFail(error));
      });
  };
};

const loadUsuariosPorClienteStart = () => {
  return {
    type: actionTypes.LOAD_USUARIOS_POR_CLIENTE_START,
  };
};

const loadUsuariosPorClienteSuccess = (usuarios) => {
  return {
    type: actionTypes.LOAD_USUARIOS_POR_CLIENTE_SUCCESS,
    usuarios,
  };
};

const loadUsuariosPorClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_USUARIOS_POR_CLIENTE_FAIL,
    error,
  };
};

export const clearUsuariosPorCliente = () => {
  return {
    type: actionTypes.CLEAR_USUARIOS_POR_CLIENTE
  };
}

export const createUser = (newUser, clienteId, success) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .post(`/usuarios/Crear`, newUser, { headers: { Authorization: authStr } })
      .then((response) => {
        if(clienteId) {
          dispatch(loadCliente(clienteId));
          dispatch(loadUsuariosPorCliente(clienteId));
        }
        
        dispatch(
          enqueueSnackbar({
            message: `Usuario creado correctamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );

        return success && success();
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data
          : `Error al crear el usuario.`;
        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

export const modificarUser = (userId, userModificado, clienteId, success) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .put(`/usuarios?id=${userId}`, userModificado, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        if(clienteId) {
          dispatch(loadCliente(clienteId));
          dispatch(loadUsuariosPorCliente(clienteId));
        }
        
        dispatch(
          enqueueSnackbar({
            message: `Usuario modificado correctamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );

        return success && success();
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data
          : `Error al modificar el usuario.`;

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

export const eliminarUser = (userId, clienteId, success) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .delete(`/usuarios?id=${userId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        clienteId && dispatch(loadUsuariosPorCliente(clienteId));
        
        dispatch(
          enqueueSnackbar({
            message: `Usuario eliminado correctamente.`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success'
            }
          })
        );

        return success && success();
      })
      .catch((error) => {
        const errorMessage = error.response
          ? error.response.data
          : `Error al eliminar el usuario.`;

        dispatch(
          enqueueSnackbar({
            message: errorMessage,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
      });
  };
};

export const ActualizarPassword = (userId, userModificado, success) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .put(`/usuarios/ActualizarPassword?id=${userId}`, userModificado, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return success && success();
      })
      .catch((error) => {
        alert(error);
      });
  };
};

export const cambiarVendedorAsignado = (data, idUser, success) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const armarData = {
      usuarioId: idUser,
      empresaId: data.id,
      vendedorId: data.idVendedor <= 0 ? "" : data.idVendedor,
    };

    axios
      .put(`/usuarios/CambiarVendedorAsignado`, armarData, {
        headers: {
          Authorization: authStr,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        return success(response);
      })
      .catch((error) => { });
  };
};

export const cleanRecuperarPassword = () => {
  return {
    type: actionTypes.CLEAN_RECUPERAR_PASSWORD,
  };
}

export const RecuperarPassword = (email) => {
  return (dispatch) => {
    dispatch(recuperarPasswordStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const cambiarPasswordUrl = urlPortalCambiarPassword();
    const userInfo = { email, cambiarPasswordUrl };

    axios
      .post(`/usuarios/RecuperarPassword`, userInfo, { headers: { Authorization: authStr }, })
      .then((response) => {
        if (response.status === 200) {
          dispatch(recuperarPasswordSuccess());
        }
      })
      .catch((error) => {
        if (error.response)
          dispatch(recuperarPasswordFail(error.response.data));
        else
          dispatch(recuperarPasswordFail("Se produjo un error conectando con el servidor"));
      });
  };
}

const recuperarPasswordStart = () => {
  return {
    type: actionTypes.RECUPERAR_PASSWORD_START,
  };
};

const recuperarPasswordSuccess = () => {
  return {
    type: actionTypes.RECUPERAR_PASSWORD_SUCCESS,
  };
};

const recuperarPasswordFail = (error) => {
  return {
    type: actionTypes.RECUPERAR_PASSWORD_FAIL,
    error: error,
  };
};

export const cleanCambiarPassword = () => {
  return {
    type: actionTypes.CLEAN_CAMBIAR_PASSWORD,
  };
}

export const CambiarPassword = (userId, userInfo) => {
  return (dispatch) => {
    dispatch(cambiarPasswordStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axios
      .post(`/usuarios/CambiarPassword?userId=${userId}`, userInfo, { headers: { Authorization: authStr }, })
      .then((response) => {
        if (response.status === 200) {
          dispatch(cambiarPasswordSuccess());
        }
      })
      .catch((error) => {
        if (error.response)
          dispatch(cambiarPasswordFail(error.response.data));
        else
          dispatch(cambiarPasswordFail("Se produjo un error conectando con el servidor"));
      });
  };
}

const cambiarPasswordStart = () => {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_START,
  };
};

const cambiarPasswordSuccess = () => {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_SUCCESS,
  };
};

const cambiarPasswordFail = (error) => {
  return {
    type: actionTypes.CAMBIAR_PASSWORD_FAIL,
    error: error,
  };
};