import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  vendedores: {
    vendedores: [],
    loading: false,
    error: null,
  },
  vendedoresPorEmpresa: {
    vendedores: [],
    loading: false,
    error: null,
  },
};

//#region loadVendedores
const loadVendedoresStart = (state) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: true,
    }),
  });
};

const loadVendedoresSuccess = (state, vendedores) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      vendedores,
    }),
  });
};

const loadVendedoresFail = (state, error) => {
  return updateObject(state, {
    vendedores: updateObject(state.vendedores, {
      loading: false,
      error,
    }),
  });
};
//#endregion

//#region loadVendedoresPorEmpresa
const loadVendedoresPorEmpresaStart = (state) => {
  return updateObject(state, {
    vendedoresPorEmpresa: updateObject(state.vendedoresPorEmpresa, {
      loading: true,
    }),
  });
};

const loadVendedoresPorEmpresaSuccess = (state, vendedores) => {
  return updateObject(state, {
    vendedoresPorEmpresa: updateObject(state.vendedoresPorEmpresa, {
      loading: false,
      vendedores,
    }),
  });
};

const loadVendedoresPorEmpresaFail = (state, error) => {
  return updateObject(state, {
    vendedoresPorEmpresa: updateObject(state.vendedoresPorEmpresa, {
      loading: false,
      error,
    }),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_VENDEDORES_START:
      return loadVendedoresStart(state);
    case actionTypes.LOAD_VENDEDORES_SUCCESS:
      return loadVendedoresSuccess(state, action.vendedores);
    case actionTypes.LOAD_VENDEDORES_FAIL:
      return loadVendedoresFail(state, action.error);

    case actionTypes.LOAD_VENDEDORES_POR_EMPRESA_START:
      return loadVendedoresPorEmpresaStart(state);
    case actionTypes.LOAD_VENDEDORES_POR_EMPRESA_SUCCESS:
      return loadVendedoresPorEmpresaSuccess(state, action.vendedores);
    case actionTypes.LOAD_VENDEDORES_POR_EMPRESA_FAIL:
      return loadVendedoresPorEmpresaFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;
