import React, { useCallback, useState } from "react";
import * as actions from "../../../store/actions/index";

import { connect } from "react-redux";
import { checkValidity } from "../../../assets/shared/utility";
import Dialog from "../../UI/Dialog/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../../UI/Button/Button";
import { Grid, IconButton, InputAdornment, Typography } from "@material-ui/core";
import { customControls, getSwitchConfig } from '../../Permisos/Common/Controls';
import { MenuItem, TextField } from "@material-ui/core";

import classes from "./ModalFormUpdateUser.css";
import { Business, PieChartOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';
import AvatarUpload from "../../UI/AvatarUpload/AvatarUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

const FormUpdateUser = (props) => {
  const classes2 = useStyles();
  const { show, onClose, onCrearUsuario, onModificarUsuario, onEliminarUsuario, onRecuperarPassword,
    user, empresas, empresasDelUsuario, onOpenModal, clienteId } = props;

  const [perfilId, setPerfilId] = useState(user.perfilId);
  const [accesoApp, setAccesoApp] = useState(user.accesoApp);
  const [password, setPassword] = useState({ value: user.password, valid: true, errorMessage: '', touched: false });
  const [showPassword, setShowPassword] = useState(false);  

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassword = (value) => {
    const validation = {
      required: true,
      minLength: 8,
      containSimbol: true,
      containNumber: true,
      containMayus: true,
      containMinus: true,
    };
    const [valid, message] = checkValidity(value, validation);
    const touched = true;
    const errorMessage = valid ? '' : message;
    setPassword({ value, valid, errorMessage, touched });
    return valid;
  }

  const onPasswordChange = (event) => {
    const value = event.target.value;
    validatePassword(value);
  }

  const setImage = (newImage) => {
    if (user && newImage) {
      user.imagen = newImage;
    }
  }

  const styleSwitch = {
    width: '100%',
    marginLeft: '0px',
    marginRight: '20px',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }

  const inputLabelProps = {
    style: {
      fontWeight: 600,
      color: 'rgba(0, 0, 0, 0.87)'
    }
  }

  const [controls, setControls] = useState({
    nombre: {
      name: "nombre",
      label: "Nombre *",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: false,
      value: user.nombre,
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    apellido: {
      name: "apellido",
      label: "Apellido *",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      disabled: false,
      value: user.apellido,
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    email: {
      name: "email",
      label: "Email *",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      value: user.email,
      validation: {
        required: true,
        isEmail: true,
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    telefono: {
      name: "telefono",
      label: "Telefono",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      value: user.telefono,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    empresa: {
      name: "empresa",
      label: "Empresa",
      placeholder: "",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      value: user.empresa,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    comentarios: {
      name: "comentarios",
      label: "Comentarios",
      elementType: "input",
      elementConfig: {
        type: "text",
        inputLabelProps
      },
      value: user.comentarios,
      validation: {
        required: false
      },
      valid: true,
      touched: false,
      errorMessage: "",
    },
    accesoWeb: getSwitchConfig("accesoWeb", "Acceso a Jalisco Web", user.accesoWeb, false, styleSwitch),
    accesoApp: getSwitchConfig("accesoApp", "Acceso a Jalisco App", user.accesoApp, false, styleSwitch),
    baja: getSwitchConfig("baja", "Baja", user.baja, false, styleSwitch),
  });

  const [formIsValid, setFormIsValid] = useState(user.nombre && user.nombre !== '' && user.apellido && user.apellido !== '' && user.email && user.email !== '' && user.perfilId);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };

    const value = updatedControl.elementType === "checkbox"
      ? event.target.checked
      : updatedControl.elementType === "select"
        ? event
        : updatedControl.elementType === "switch"
          ? !updatedControl.value
          : event.target.value;

    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = isValid && user.id ? true : validatePassword(password.value);
    for (let formElementKey in updatedControls) {
      if (updatedControls[formElementKey].validation) {
        const [updatedControlValid, updatedControlErrorMessage] = checkValidity(updatedControls[formElementKey].value, updatedControls[formElementKey].validation);
        formIsValid = formIsValid && updatedControlValid;

        if (!updatedControlValid) {
          updatedControls[formElementKey].valid = updatedControlValid;
          updatedControls[formElementKey].errorMessage = updatedControlErrorMessage;
          updatedControls[formElementKey].touched = true;
        }
      }
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);

    if (updatedControl.elementType === "switch" && updatedControl.name === "accesoApp")
      setAccesoApp(value);
  };

  let form = customControls(controls, inputValueChangedHandler);

  const onCreateUsuarioClick = () => {
    const armarNuevoUser = {
      isAdmin: false,
      nombre: controls.nombre.value,
      apellido: controls.apellido.value,
      email: controls.email.value,
      telefono: controls.telefono.value,
      empresa: controls.empresa.value,
      empresasId: empresas ? empresas.map((e) => e.id) : [],
      empresasHabilitadas: [],
      indicadores: [],
      comentarios: controls.comentarios.value,
      baja: controls.baja.value,
      accesoApp: accesoApp,
      accesoWeb: controls.accesoWeb.value,
      perfilId: perfilId,
      gauss: false,
      demo: false,
      monedaId: "PES",
      clienteId: clienteId,
      imagen: user.imagen,
      password: password && password.value,
    };
    onCrearUsuario(armarNuevoUser, clienteId, () => { onClose(); });
  }

  const onGuardarCambiosClick = () => {
    const usuario = {
      id: user.id,
      isAdmin: user.isAdmin,
      nombre: controls.nombre.value,
      apellido: controls.apellido.value,
      email: controls.email.value,
      telefono: controls.telefono.value,
      empresa: controls.empresa.value,
      empresasHabilitadas: empresasDelUsuario ? empresasDelUsuario.map((e) => e.idEmpresa) : [],
      indicadores: [],
      comentarios: controls.comentarios.value,
      baja: controls.baja.value,
      accesoApp: accesoApp,
      accesoWeb: controls.accesoWeb.value,
      perfilId: perfilId,
      clienteId: clienteId,
      imagen: user.imagen,
      password: user.password
    };

    onModificarUsuario(user.id, usuario, clienteId, () => { onClose(); });
  };

  const onEliminarUsuarioClick = () => {
    onEliminarUsuario(user.id, clienteId, () => { onClose(); });
  }

  const body = () => {
    return (
      <Grid container spacing={1} direction="row" justifyContent="space-between" alignContent="center">
        <Grid item xs={6} lg={1} className={classes2.root}>
          <AvatarUpload
            className={classes2.large}
            image={user.imagen}
            setImage={setImage}
          />
        </Grid>
        <Grid item xs={12} lg={11} className={classes.FormUpdateUserContainer}>
          <Grid item xs={12} className={classes.flexRow}>
            <Grid item xs={6} lg={3}>
              {form[0]}
            </Grid>
            <Grid item xs={6} lg={3}>
              {form[1]}
            </Grid>
            <Grid item xs={6} lg={4}>
              {form[2]}
            </Grid>
            <Grid item xs={6} lg={2}>
              {form[3]}
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.flexRow}>
            <Grid item xs={6} lg={3}>
              {form[4]}
            </Grid>
            <Grid item xs={6} lg={3} className="MuiFormControl-root">
              <TextField
                id={`selectPerfil${user.id}`}
                label="Rol"
                select
                size="small"
                style={{ minWidth: '100px', width: '90%' }}
                value={perfilId}
                onChange={(event) => setPerfilId(event.target.value)}
                InputLabelProps={inputLabelProps}
                required={true}
              >
                {props.perfiles.map((perfil) => (
                  <MenuItem
                    key={perfil.id}
                    value={perfil.id}
                    style={{ fontSize: '12px' }}
                  >
                    {perfil.nombre}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} lg={4}>
              {form[5]}
            </Grid>
            <Grid item xs={6} lg={2} className="MuiFormControl-root">
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                data-shrink="true" id="indicadores-label" style={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>Indicadores a visualizar</label>
              <IconButton
                style={{ maxWidth: '50px', marginTop: '10px' }}
                onClick={() => onOpenModal(user.id, user.email, "Indicadores")}
                disabled={!accesoApp}
              >
                <PieChartOutlined />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.flexRow}>
            <Grid item xs={6} lg={user.id ? 3 : 2}>
              {form[6]}
            </Grid>
            <Grid item xs={6} lg={user.id ? 3 : 2}>
              {form[7]}
            </Grid>
            <Grid item xs={6} lg={user.id ? 4 : 2}>
              {form[8]}
            </Grid>
            {!user.id && (<Grid item xs={6} lg={4}>
              <TextField
                id="password"
                label="Password"
                value={password && password.value}
                onChange={onPasswordChange}
                type={showPassword ? 'text' : 'password'}
                fullWidth={true}
                required={true}
                autoComplete="new-password"
                style={{ maxWidth: '90%' }}
                error={password && !password.valid}
                helperText={password && password.errorMessage}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Grid>)}
            <Grid item xs={6} lg={2} className="MuiFormControl-root">
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled"
                data-shrink="true" id="empresas-label" style={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>Empresas habilitadas</label>
              <IconButton
                style={{ maxWidth: '50px', marginTop: '10px' }}
                onClick={() => onOpenModal(user.id, user.email, "Empresas")}
              >
                <Business color="primary" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} container className={classes.flexRow}>
            <Grid item xs={4} lg={3}>
              {user.id && (<Button
                disabled={user.id && (!user.email || user.email === '')}
                onClick={() => onRecuperarPassword(user.email)}
                style={{ backgroundImage: "linear-gradient(45deg, #fbb040, #f15a29)", }}
              >
                Resetear contraseña
              </Button>)}
            </Grid>
            <Grid item xs={false} lg={5}></Grid>
            <Grid item xs={4} lg={2}>
              {user.id && (<Button
                disabled={!formIsValid && (!password || !password.valid)}
                onClick={onEliminarUsuarioClick}
                style={{ backgroundColor: 'red' }}
              >
                Eliminar usuario
              </Button>)}
            </Grid>
            <Grid item xs={4} lg={2}>
              <Button
                disabled={!formIsValid && (!password || !password.valid)}
                onClick={() => user.id ? onGuardarCambiosClick() : onCreateUsuarioClick()}
              >
                {user.id ? 'Guardar Cambios' : 'Crear Usuario'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      title={
        <div classes={classes.titleContent} style={{ width: "100%" }}>
          <div className={classes.titleTexto}>
            <Typography variant="h5">Modificar usuario</Typography>
            <IconButton aria-label="close" onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      }
      maxWidth="lg"
      fullWidth
      show={show}
      onClose={onClose}
      body={body()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    perfiles: state.permisos.loadPerfiles.items,
    empresasDelUsuario: state.empresas.empresasAccesoUsuario.empresas,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCrearUsuario: (nuevoUser, clienteId, success) => dispatch(actions.createUser(nuevoUser, clienteId, success)),
    onModificarUsuario: (userId, userModificado, clienteId, success) => dispatch(actions.modificarUser(userId, userModificado, clienteId, success)),
    onEliminarUsuario: (userId, clienteId, success) => dispatch(actions.eliminarUser(userId, clienteId, success)),
    onOpenModal: (userId, userEmail, appConfig) => dispatch(actions.openModalUserConfig(userId, userEmail, appConfig)),
    onRecuperarPassword: (email) => dispatch(actions.RecuperarPassword(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FormUpdateUser);
