import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "../Styles/Permisos.css";
import { Fragment } from "react";
import { Typography } from "@material-ui/core";
import { CardPerfilClienteAplicacion } from "..";
import Button from "../../UI/Button/Button";
import { checkValidity } from "../../../assets/shared/utility";
import { customControls } from "../Common/Controls";
import Spinner from "../../UI/Spinner/Spinner";

const CardPerfilCliente = (props) => {
  const {
    nuevo,
    info,
    inEdit,
    setInEdit,
    close,
    onSuccessUpdateOrCreate,
  } = props;
  
  const [items, setItems] = useState(null);
  const [inEditItem, setInEditItem] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);
  const [tempInfo, setTempInfo] = useState(info);

  const cargarItems = useCallback((perfilesAccesos) => {
    const tempItems = [];

    props.aplicaciones.forEach((aplicacion) => {
      const itemsFiltrados = perfilesAccesos === undefined ? [] : perfilesAccesos.filter((perfil) => perfil.aplicacionId === aplicacion.id);
      const incluido = itemsFiltrados !== undefined && itemsFiltrados.length !== 0;
      const secciones = incluido
        ? itemsFiltrados.map((itemFiltrado) => { return { id: itemFiltrado.seccionId } })
        : [];

      const item = {
        perfilId: tempInfo ? tempInfo.id : 0,
        aplicacionId: aplicacion.id,
        incluido: incluido,
        secciones: secciones
      }

      tempItems.push(item);
    });
    setItems(tempItems);
  }, [tempInfo, props.aplicaciones]);

  useEffect(() => {
    const accessos = nuevo || tempInfo === null ? [] : tempInfo.perfilesAccesos;
    cargarItems(accessos);
  }, [nuevo, tempInfo, cargarItems]);
  
  //#region Form
  const [formIsValid, setFormIsValid] = useState(false);
  const [controls, setControls] = useState({});

  const updateControls = useCallback((editar = false) => {
    const tempControls = {
      nombre: {
        elementType: "input",
        name: "nombrePerfil",
        elementConfig: {
          type: "text",
          placeholder: "Nombre perfil",
        },
        validation: {
          required: true,
        },
        disabled: !editar,
        value: tempInfo ? tempInfo.nombre : "",
        valid: true,
        touched: false,
        errorMessage: "",
        elementStyle: { width: '100%' }
      },    
    };

    setControls(tempControls);
  }, []);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    const value = event.target.value;
    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, updatedControl.validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  }; 

  useEffect(() => {
    updateControls(false);
  }, []);

  useEffect(() => {
    if(inEdit !== inEditItem){
      setInEditItem(inEdit);
      updateControls(inEdit);
    }
  }, [inEdit, inEditItem]);

  let form = customControls(controls, inputValueChangedHandler);

  //#endregion

  //#region Actions
  const inEditChange = (edit) => {
    const tempItems = items;
    setLoadingItem(true);
    setTempInfo(null);
    setItems([]);
    setInEditItem(edit);
    setInEdit(edit);
    updateControls(edit);
    setTimeout(() => {
      setTempInfo(info);
      setItems(tempItems);
      setLoadingItem(false);
    }, 500);
  }

  const getItem = () => {
    let perfiles = [];

    items.forEach((item) => {
      if(item.incluido){  
        if(item.secciones !== undefined && item.secciones.length !== 0){
          item.secciones.forEach((seccion) => {            
            let perfil = {
              perfilId: item.perfilId,
              aplicacionId: item.aplicacionId,
              seccionId: seccion.id ? seccion.id : seccion,
            };

            perfiles.push(perfil);
          });
        }
      }
    });
    
    let item = {
      id: tempInfo ? tempInfo.id : 0,      
      clienteId: sessionStorage.getItem("clienteId"),
      nombre: controls.nombre.value,
      perfilesAccesos: perfiles
    };

    return item;
  }

  const onSuccess = (data) => {
    if(nuevo){
      close();
      onSuccessUpdateOrCreate(null);
    }else{
      setTempInfo(data);
      onSuccessUpdateOrCreate(data);
    }   
    inEditChange(!inEditItem);   
  }

  const onError = (error) => {
    console.log(error);
  }

  const submitHandler = () => {
    const item = getItem();

    if(item !== undefined){
      props.onCreateItem(item, onSuccess, onError);
    }
  }

  const onEditOrCancelClick = () => {
    inEditChange(!inEditItem);
  }

  const onGuardarCambiosClick = () => {
    if(formIsValid){
      const item = getItem();

      if(item !== undefined){
        props.onUpdateItem(item, onSuccess, onError);
      }
    }
  }

  //#endregion

  return (
    <Fragment>
      <form>
        <div className={classes.titleCard}>
          <div style={{ width: '45%' }}>
            {form[0]}
          </div>
        </div>
        <div className={classes.titleCard} style={{ marginTop: '20px' }}> 
          <Typography className={classes.perfilClienteAplicaciones}>
            Aplicaciones
          </Typography>
        </div>
        {loadingItem ? (
          <div className={classes.spinnerItems}>
            <Spinner />
          </div>
        ) : (
          <div className={classes.cardsItems}>
            {items && items.map((item, index) => (
              <CardPerfilClienteAplicacion 
                key={index} 
                index={index}
                info={item} 
                perfilId={tempInfo ? tempInfo.id : 0}
                inEdit={inEditItem}
                nuevo={nuevo}
                setInEdit={inEditChange}
              />
            ))}
          </div>
        )}
        {inEditItem && (
          <div className={classes.titleCard} style={{ justifyContent: "right"}}>
            <Button
              style={{
                color: 'white',
                textTransform: 'capitalize', 
                backgroundColor: '#E45164',  
                minWidth: '150px',
                height: '25px'                      
              }}
              onClick={() => onEditOrCancelClick()}
              classes={{
                root: classes.actionButton,
                label: classes.actionButtonLabel,
              }}
            >
              Cancelar
            </Button>
            <Button
              style={{
                color: 'white',
                textTransform: 'capitalize', 
                backgroundColor: '#00BCD4',
                minWidth: '150px',
                height: '25px'              
              }}
              onClick={() => nuevo ? submitHandler() : onGuardarCambiosClick()}
              classes={{
                root: classes.actionButton,
                label: classes.actionButtonLabel,
              }}
            >
              Guardar cambios
            </Button>
          </div>
        )}
      </form>
    </Fragment>
  )
}

const mapStateToProps = (state) => {
  return {    
    aplicaciones: state.permisos.loadAplicaciones.items,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateItem: (item, success, error) => dispatch(actions.crearPerfilCliente(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarPerfilCliente(item, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPerfilCliente);