import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  loadAplicaciones: {
    items: [],
    estaCargado: false,
    loading: false,
    error: null,
  },
  loadSecciones: {
    items: [],
    estaCargado: false,
    loading: false,
    error: null,
  },
  loadPlanes: {
    items: [],
    estaCargado: false,
    loading: false,
    error: null,
  },
  loadPerfiles: {
    items: [],
    estaCargado: false,
    loading: false,
    error: null,
  },  
  loadPerfilesCliente: {
    items: [],
    estaCargado: false,
    loading: false,
    error: null,
  },  
  loadPerfilesClienteAsignacion: {
    items: [],
    estaCargado: false,
    loading: false,
    error: null,
  },
};

//#region Aplicaciones
const loadAplicacionesStart = (state) => {
  return updateObject(state, {
    loadAplicaciones: updateObject(state.loadAplicaciones, {
      loading: true,
    }),
  });
};

const loadAplicacionesSuccess = (state, items) => {
  return updateObject(state, {
    loadAplicaciones: updateObject(state.loadAplicaciones, {
      items,
      loading: false,
    }),
  });
};

const loadAplicacionesFail = (state, error) => {
  return updateObject(state, {
    loadAplicaciones: updateObject(state.loadAplicaciones, {
      error,
      loading: false,
    }),
  });
};

const clearAplicaciones = (state) => {
  return updateObject(state, {
    loadAplicaciones: updateObject(state.loadAplicaciones, {
      items: [],
      estaCargado: false,
      loading: false,
      error: null,
    }),
  });
}

//#endregion

//#region Planes

const loadPlanesStart = (state) => {
  return updateObject(state, {
    loadPlanes: updateObject(state.loadPlanes, {
      loading: true,
    }),
  });
};

const loadPlanesSuccess = (state, items) => {
  return updateObject(state, {
    loadPlanes: updateObject(state.loadPlanes, {
      items,
      loading: false,
    }),
  });
};

const loadPlanesFail = (state, error) => {
  return updateObject(state, {
    loadPlanes: updateObject(state.loadPlanes, {
      error,
      loading: false,
    }),
  });
};

const clearPlanes = (state) => {
  return updateObject(state, {
    loadPlanes: updateObject(state.loadPlanes, {
      items: [],
      estaCargado: false,
      loading: false,
      error: null,
    }),
  });
}

//#endregion

//#region Perfiles

const loadPerfilesStart = (state) => {
  return updateObject(state, {
    loadPerfiles: updateObject(state.loadPerfiles, {
      loading: true,
    }),
  });
};

const loadPerfilesSuccess = (state, items) => {
  return updateObject(state, {
    loadPerfiles: updateObject(state.loadPerfiles, {
      items,
      loading: false,
    }),
  });
};

const loadPerfilesFail = (state, error) => {
  return updateObject(state, {
    loadPerfiles: updateObject(state.loadPerfiles, {
      error,
      loading: false,
    }),
  });
};

const clearPerfiles = (state) => {
  return updateObject(state, {
    loadPerfiles: updateObject(state.loadPerfiles, {
      items: [],
      estaCargado: false,
      loading: false,
      error: null,
    }),
  });
}

//#endregion

//#region Perfiles Cliente

const loadPerfilesClienteStart = (state) => {
  return updateObject(state, {
    loadPerfilesCliente: updateObject(state.loadPerfilesCliente, {
      loading: true,
    }),
  });
};

const loadPerfilesClienteSuccess = (state, items) => {
  return updateObject(state, {
    loadPerfilesCliente: updateObject(state.loadPerfilesCliente, {
      items,
      loading: false,
    }),
  });
};

const loadPerfilesClienteFail = (state, error) => {
  return updateObject(state, {
    loadPerfilesCliente: updateObject(state.loadPerfilesCliente, {
      error,
      loading: false,
    }),
  });
};

const clearPerfilesCliente = (state) => {
  return updateObject(state, {
    loadPerfilesCliente: updateObject(state.loadPerfilesCliente, {
      items: [],
      estaCargado: false,
      loading: false,
      error: null,
    }),
  });
}

//#endregion

//#region Perfiles Cliente Asignacion

const loadPerfilesClienteAsignacionStart = (state) => {
  return updateObject(state, {
    loadPerfilesClienteAsignacion: updateObject(state.loadPerfilesClienteAsignacion, {
      loading: true,
    }),
  });
};

const loadPerfilesClienteAsignacionSuccess = (state, items) => {
  return updateObject(state, {
    loadPerfilesClienteAsignacion: updateObject(state.loadPerfilesClienteAsignacion, {
      items,
      loading: false,
    }),
  });
};

const loadPerfilesClienteAsignacionFail = (state, error) => {
  return updateObject(state, {
    loadPerfilesClienteAsignacion: updateObject(state.loadPerfilesClienteAsignacion, {
      error,
      loading: false,
    }),
  });
};

const clearPerfilesClienteAsignacion = (state) => {
  return updateObject(state, {
    loadPerfilesClienteAsignacion: updateObject(state.loadPerfilesClienteAsignacion, {
      items: [],
      estaCargado: false,
      loading: false,
      error: null,
    }),
  });
}

//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_APLICACIONES_START:
      return loadAplicacionesStart(state);
    case actionTypes.LOAD_APLICACIONES_SUCCESS:
      return loadAplicacionesSuccess(state, action.aplicaciones);
    case actionTypes.LOAD_APLICACIONES_FAIL:
      return loadAplicacionesFail(state, action.error);
    case actionTypes.CLEAR_APLICACIONES:
      return clearAplicaciones(state);

    case actionTypes.LOAD_PERFILES_START:
      return loadPerfilesStart(state);
    case actionTypes.LOAD_PERFILES_SUCCESS:
      return loadPerfilesSuccess(state, action.perfiles);
    case actionTypes.LOAD_PERFILES_FAIL:
      return loadPerfilesFail(state, action.error);
    case actionTypes.CLEAR_PERFILES:
      return clearPerfiles(state);

    case actionTypes.LOAD_PLANES_START:
      return loadPlanesStart(state);
    case actionTypes.LOAD_PLANES_SUCCESS:
      return loadPlanesSuccess(state, action.planes);
    case actionTypes.LOAD_PLANES_FAIL:
      return loadPlanesFail(state, action.error);
    case actionTypes.CLEAR_PLANES:
      return clearPlanes(state);

    case actionTypes.LOAD_PERFILES_CLIENTE_START:
      return loadPerfilesClienteStart(state);
    case actionTypes.LOAD_PERFILES_CLIENTE_SUCCESS:
      return loadPerfilesClienteSuccess(state, action.perfiles);
    case actionTypes.LOAD_PERFILES_CLIENTE_FAIL:
      return loadPerfilesClienteFail(state, action.error);
    case actionTypes.CLEAR_PERFILES_CLIENTE:
      return clearPerfilesCliente(state);

    case actionTypes.LOAD_PERFILES_CLIENTE_ASIGNACION_START:
      return loadPerfilesClienteAsignacionStart(state);
    case actionTypes.LOAD_PERFILES_CLIENTE_ASIGNACION_SUCCESS:
      return loadPerfilesClienteAsignacionSuccess(state, action.perfiles);
    case actionTypes.LOAD_PERFILES_CLIENTE_ASIGNACION_FAIL:
      return loadPerfilesClienteAsignacionFail(state, action.error);
    case actionTypes.CLEAR_PERFILES_CLIENTE_ASIGNACION:
      return clearPerfilesClienteAsignacion(state);

    default:
      return state;
  }
};

export default reducer;