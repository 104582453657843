// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ListUsersDataTable__ListUserDataTableContainer__ZtPWy {\r\n    margin: auto;\r\n    border-radius: 10px !important;\r\n    background-color: #FFFFFF;\r\n}\r\n\r\n.ListUsersDataTable__ListUserTable__shtnb{    \r\n    max-width: -webkit-max-content;    \r\n    max-width: max-content;\r\n}\r\n\r\n.ListUsersDataTable__ListUserTableTitle__ZBfGP {\r\n    color: rgb(0, 188, 212);\r\n    font-size: 15px;\r\n}\r\n\r\n.ListUsersDataTable__LicenciasContainer__odGe4 {\r\n    justify-self: flex-end;\r\n    margin-block: 15px;\r\n}\r\n\r\n.ListUsersDataTable__SpeedDialIconRoot__RX4eY {\r\n    background-color: #1976d2 !important;\r\n    color: #FFFFFF !important;\r\n}\r\n\r\n.ListUsersDataTable__StaticTooltipLabel__RakLG {\r\n    min-width: 100px;\r\n    font-size: small !important;\r\n    background-color: #1976d2 !important;\r\n    color: #FFFFFF !important;\r\n    text-align: start !important;\r\n}\r\n\r\n.ListUsersDataTable__actionButton__-mWt5 {\r\n  text-transform: none;\r\n  background-color: transparent;\r\n  box-shadow: none;\r\n  border: none !important;\r\n  padding: 8px;\r\n  min-width: 10px;\r\n  min-height: 10px;\r\n  border-radius: 50%\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ListUserDataTableContainer": "ListUsersDataTable__ListUserDataTableContainer__ZtPWy",
	"ListUserTable": "ListUsersDataTable__ListUserTable__shtnb",
	"ListUserTableTitle": "ListUsersDataTable__ListUserTableTitle__ZBfGP",
	"LicenciasContainer": "ListUsersDataTable__LicenciasContainer__odGe4",
	"SpeedDialIconRoot": "ListUsersDataTable__SpeedDialIconRoot__RX4eY",
	"StaticTooltipLabel": "ListUsersDataTable__StaticTooltipLabel__RakLG",
	"actionButton": "ListUsersDataTable__actionButton__-mWt5"
};
export default ___CSS_LOADER_EXPORT___;
