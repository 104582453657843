// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Permisos__ContainerPermisos__-mq6k {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n}\r\n\r\n.Permisos__ContainerAplicaciones__SXSOS {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.Permisos__CardAplicaciones__-e6Kq {\r\n  display: block;\r\n  margin: 20px 10px 20px 20px;\r\n}\r\n\r\n.Permisos__ContainerPlanesPerfiles__Mq\\+RQ {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.Permisos__CardPlanes__F6XB0 {\r\n  display: block;\r\n  margin: 20px 20px 5px 10px;\r\n}\r\n\r\n.Permisos__CardPerfiles__29PeR {\r\n  display: block;\r\n  margin: 5px 20px 20px 10px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContainerPermisos": "Permisos__ContainerPermisos__-mq6k",
	"ContainerAplicaciones": "Permisos__ContainerAplicaciones__SXSOS",
	"CardAplicaciones": "Permisos__CardAplicaciones__-e6Kq",
	"ContainerPlanesPerfiles": "Permisos__ContainerPlanesPerfiles__Mq+RQ",
	"CardPlanes": "Permisos__CardPlanes__F6XB0",
	"CardPerfiles": "Permisos__CardPerfiles__29PeR"
};
export default ___CSS_LOADER_EXPORT___;
