import * as actionTypes from "./actionTypes";
import axios from "../../axios-instance";
import { storageToken } from "../../assets/shared/sessionData";
import { enqueueSnackbar } from './snackbar';

//#region Aplicaciones

export const clearAplicaciones = () => {
  return {
    type: actionTypes.CLEAR_APLICACIONES,
  };
}

export const loadAplicaciones = (idCliente) => {
  return (dispatch) => {
    dispatch(loadAplicacionesStart());

    const authStr = "Bearer ".concat(storageToken());
    axios
      .get(`/permisos/obtenerAplicaciones${
        idCliente ? `?idCliente=${idCliente}` : ''
      }`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadAplicacionesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadAplicacionesFail(error));
      });
  };
};

const loadAplicacionesStart = () => {
  return {
    type: actionTypes.LOAD_APLICACIONES_START,
  };
};

const loadAplicacionesSuccess = (aplicaciones) => {
  return {
    type: actionTypes.LOAD_APLICACIONES_SUCCESS,
    aplicaciones,
  };
};

const loadAplicacionesFail = (error) => {
  return {
    type: actionTypes.LOAD_APLICACIONES_FAIL,
    error,
  };
};

export const clearSecciones = () => {
  return {
    type: actionTypes.CLEAR_SECCIONES,
  };
}

export const crearAplicacion = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/crearAplicacion`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const actualizarAplicacion = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .put(`/permisos/actualizarAplicacion?appId=${data.id}`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const eliminarAplicacion = (id, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .delete(`/permisos/eliminarAplicacion?id=${id}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

//#endregion

//#region Secciones

export const loadSecciones = (idAplicacion) => {
  return (dispatch) => {
    dispatch(loadSeccionesStart());

    const authStr = "Bearer ".concat(storageToken());
    axios
      .get(`/permisos/obtenerSecciones?idAplicacion=${idAplicacion}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadSeccionesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadSeccionesFail(error));
      });
  };
};

const loadSeccionesStart = () => {
  return {
    type: actionTypes.LOAD_SECCIONES_START,
  };
};

const loadSeccionesSuccess = (secciones) => {
  return {
    type: actionTypes.LOAD_SECCIONES_SUCCESS,
    secciones,
  };
};

const loadSeccionesFail = (error) => {
  return {
    type: actionTypes.LOAD_SECCIONES_FAIL,
    error,
  };
};

export const crearSeccion = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/crearSeccion`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const actualizarSeccion = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .put(`/permisos/actualizarSeccion?seccionId=${data.id}`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const eliminarSeccion = (id, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .delete(`/permisos/eliminarSeccion?id=${id}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

//#endregion

//#region Planes

export const clearPlanes = () => {
  return {
    type: actionTypes.CLEAR_PLANES,
  };
}

export const loadPlanes = () => {
  return (dispatch) => {
    dispatch(loadPlanesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/permisos/obtenerPlanes`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadPlanesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPlanesFail(error));
      });
  };
};

const loadPlanesStart = () => {
  return {
    type: actionTypes.LOAD_PLANES_START,
  };
};

const loadPlanesSuccess = (planes) => {
  return {
    type: actionTypes.LOAD_PLANES_SUCCESS,
    planes,
  };
};

const loadPlanesFail = (error) => {
  return {
    type: actionTypes.LOAD_PLANES_FAIL,
    error,
  };
};

export const crearPlan = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/crearPlan`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const actualizarPlan = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .put(`/permisos/actualizarPlan?planId=${data.id}`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const eliminarPlan = (id, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .delete(`/permisos/eliminarPlan?id=${id}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

//#endregion

//#region Perfiles

export const clearPerfiles = () => {
  return {
    type: actionTypes.CLEAR_PERFILES,
  };
}

export const loadPerfiles = () => {
  return (dispatch) => {
    dispatch(loadPerfilesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/permisos/obtenerPerfiles`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadPerfilesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPerfilesFail(error));
      });
  };
};

const loadPerfilesStart = () => {
  return {
    type: actionTypes.LOAD_PERFILES_START,
  };
};

const loadPerfilesSuccess = (perfiles) => {
  return {
    type: actionTypes.LOAD_PERFILES_SUCCESS,
    perfiles,
  };
};

const loadPerfilesFail = (error) => {
  return {
    type: actionTypes.LOAD_PERFILES_FAIL,
    error,
  };
};

export const crearPerfil = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/crearPerfil`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const actualizarPerfil = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .put(`/permisos/actualizarPerfil?perfilId=${data.id}`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const eliminarPerfil = (id, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .delete(`/permisos/eliminarPerfil?id=${id}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

//#endregion

//#region Clientes

export const loadClientesPermisos = () => {
  return (dispatch) => {
    dispatch(loadClientesStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    axios
      .get(`/permisos/clientes`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadClientesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadClientesFail(error));
      });
  };
};

const loadClientesStart = () => {
  return {
    type: actionTypes.LOAD_CLIENTES_START,
  };
};

const loadClientesSuccess = (clientes) => {
  return {
    type: actionTypes.LOAD_CLIENTES_SUCCESS,
    clientes,
  };
};

const loadClientesFail = (error) => {
  return {
    type: actionTypes.LOAD_CLIENTES_FAIL,
    error,
  };
};

export const clientSelected = (client) => {
  return {
    type: actionTypes.CLIENT_SELECTED,
    client,
  };
};

//#endregion

//#region Planes Acceso

export const crearPlanesAccesos = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/crearPlanesAccesos`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const actualizarPlanesAccesos = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .put(`/permisos/actualizarPlanesAccesos`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

export const eliminarPlanesAccesos = (data, onSuccess, onError) => {
  return () => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/eliminarPlanesAccesos`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(true, response.data);
      })
      .catch((error) => {
        return onError(error);
      });
  };
}

//#endregion

//#region Perfiles de Acceso

export const clearPerfilesCliente = () => {
  return {
    type: actionTypes.CLEAR_PERFILES,
  };
}

export const loadPerfilesCliente = () => {
  return (dispatch) => {
    dispatch(loadPerfilesClienteStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const clienteId = sessionStorage.getItem("clienteId");

    axios
      .get(`/permisos/obtenerPerfilesCliente?id=${clienteId}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadPerfilesClienteSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPerfilesClienteFail(error));
      });
  };
};

const loadPerfilesClienteStart = () => {
  return {
    type: actionTypes.LOAD_PERFILES_CLIENTE_START,
  };
};

const loadPerfilesClienteSuccess = (perfiles) => {
  return {
    type: actionTypes.LOAD_PERFILES_CLIENTE_SUCCESS,
    perfiles,
  };
};

const loadPerfilesClienteFail = (error) => {
  return {
    type: actionTypes.LOAD_PERFILES_CLIENTE_FAIL,
    error,
  };
};

export const clearPerfilesClienteAsignacion = () => {
  return {
    type: actionTypes.CLEAR_PERFILES,
  };
}

export const loadPerfilesClienteAsignacion = () => {
  return (dispatch) => {
    dispatch(loadPerfilesClienteAsignacionStart());

    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));
    const clienteId = sessionStorage.getItem("clienteId");

    axios
      .get(`/permisos/obtenerPerfilesCliente?id=${clienteId}&incluirDefault=${true}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadPerfilesClienteAsignacionSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadPerfilesClienteAsignacionFail(error));
      });
  };
};

const loadPerfilesClienteAsignacionStart = () => {
  return {
    type: actionTypes.LOAD_PERFILES_CLIENTE_ASIGNACION_START,
  };
};

const loadPerfilesClienteAsignacionSuccess = (perfiles) => {
  return {
    type: actionTypes.LOAD_PERFILES_CLIENTE_ASIGNACION_SUCCESS,
    perfiles,
  };
};

const loadPerfilesClienteAsignacionFail = (error) => {
  return {
    type: actionTypes.LOAD_PERFILES_CLIENTE_ASIGNACION_FAIL,
    error,
  };
};

export const crearPerfilCliente = (data, onSuccess, onError) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .post(`/permisos/crearPerfil`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: `Error al crear el perfil de acceso!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        return onError(error);
      });
  };
}

export const actualizarPerfilCliente = (data, onSuccess, onError) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .put(`/permisos/actualizarPerfilesAccesos`, data, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(response.data);
      })
      .catch((error) => {dispatch(
        enqueueSnackbar({
          message: `Error al actualizar el perfil de acceso!`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error'
          }})
        );
        return onError(error);
      });
  };
}

export const eliminarPerfilCliente = (data, onSuccess, onError) => {
  return (dispatch) => {
    const authStr = "Bearer ".concat(storageToken());
    axios
      .delete(`/permisos/eliminarPerfil?id=${data.id}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        return onSuccess(true, response.data);
      })
      .catch((error) => {
        dispatch(
          enqueueSnackbar({
            message: `Error al eliminar el perfil de acceso!`,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error'
            }
          })
        );
        return onError(error);
      });
  };
}

//#endregion