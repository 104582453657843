import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "../Styles/Permisos.css";
import { CardItems, EditActions } from "../Common";
import { checkValidity } from "../../../assets/shared/utility";
import { getCheckboxConfig, customControls } from "../Common/Controls";
import { Fragment } from "react";

const CardPerfil = (props) => {
  const {
    nuevo,
    info,
    close,
    onSuccessUpdateOrCreate,
  } = props;
  
  const [items, setItems] = useState([]);
  const [onEdit, setOnEdit] = useState(false);

  useEffect(() => {
    if(!nuevo && items.length === 0 && info !== undefined)
      setItems(info.usuarios);
  }, [nuevo, items, info, setItems]);

  //#region Form
  const [formIsValid, setFormIsValid] = useState(false);
  const disabledCheck = info ? true : false;

  const [controls, setControls] = useState({
    nombre: {
      elementType: "input",
      name: "nombrePerfil",
      elementConfig: {
        type: "text",
        placeholder: "Nombre perfil",
      },
      validation: {
        required: true,
      },
      disabled: info ? true : false,
      value: info ? info.nombre : "",
      valid: true,
      touched: false,
      errorMessage: "",
      elementStyle: { width: '100%' }
    },    
  });
  
  const [controlsChecks, setControlsChecks] = useState({
    default: getCheckboxConfig("defaultCheckbox", "Default", info ? info.default : false, disabledCheck, { width: '20%' }),
    vendedor: getCheckboxConfig("vendedorCheckbox", "Vendedor", info ? info.vendedor : false, disabledCheck, { width: '20%' }),
    gauss: getCheckboxConfig("gaussCheckbox", "Gauss", info ? info.gauss : false, disabledCheck, { width: '20%' }),
    administradorAplicaciones: getCheckboxConfig("adminAppCheckbox", "Administrador aplicaciones", info ? info.administradorAplicaciones : false, disabledCheck, { width: '35%' }),
    administrador: getCheckboxConfig("adminCheckbox", "Administrador", info ? info.administrador : false, disabledCheck, { width: '20%' }),
    demo: getCheckboxConfig("demoCheckbox", "Demo", info ? info.demo : false, disabledCheck, { width: '20%' }),
    consultor: getCheckboxConfig("consultorCheckbox", "Consultor", info ? info.consultor : false, disabledCheck, { width: '20%' }),
    cliente: getCheckboxConfig("clientesCheckbox", "Clientes de clientes", info ? info.cliente : false, disabledCheck, { width: '35%' }),
  });  

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    const value = event.target.value;
    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, updatedControl.validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  const checkboxValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controlsChecks[inputId] };
    const value = event.target.checked;
    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, updatedControl.validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controlsChecks,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControlsChecks(updatedControls);
    setFormIsValid(formIsValid);
  };

  let form = customControls(controls, inputValueChangedHandler);
  let formChecks = customControls(controlsChecks, checkboxValueChangedHandler);

  //#endregion

  //#region Actions
  const getItem = () => {
    let item = {
      id: info ? info.id : 0,
    };

    for(let key in controls){
      item = {
        ...item,
        [key]: controls[key].value
      };
    }

    for(let key in controlsChecks){
      item = {
        ...item,
        [key]: controlsChecks[key].value
      };
    }

    return item;
  }

  const onSuccess = (data) => {
    onEditOrCancelItemClick();

    if(nuevo){
      close();
      onSuccessUpdateOrCreate(null);
    }else{
      onSuccessUpdateOrCreate(data);
    }      
  }

  const onError = (error) => {
    console.log(error);
  }

  const onEliminarSuccess = () => {
    onEditOrCancelItemClick();
    onSuccessUpdateOrCreate(null);
  }

  const submitHandler = () => {
    if(formIsValid){
      const item = getItem();
      props.onCreateItem(item, onSuccess, onError);
    }
  }

  const onEditOrCancelItemClick = (restartValues) => {
    const updatedControls = { ...controls };

    for (let key in controls) {
      updatedControls[key].disabled = !controls[key].disabled;
    }

    if (restartValues) {
      updatedControls.nombre.value = info.nombre;
      updatedControls.nombre.valid = true;
    }
    
    setControls(updatedControls);

    const updatedControlsChecks = { ...controlsChecks };

    for (let key in controlsChecks) {
      updatedControlsChecks[key].disabled = !controlsChecks[key].disabled;

      if(restartValues){
        updatedControlsChecks[key].valid = true;

        if(info !== null && info !== undefined)
          updatedControlsChecks[key].value = info[key];
      }
    }

    setControlsChecks(updatedControlsChecks);

    setFormIsValid(true);
    setOnEdit(!onEdit);
  };

  const onGuardarCambiosClick = () => {
    if(formIsValid){
      const item = getItem();
      props.onUpdateItem(item, onSuccess, onError);
    }
  }

  const onEliminarClick = () => {
    const item = getItem();
    props.onDeleteItem(item.id, onEliminarSuccess, onError);
  }

  //#endregion

  return (
    <form>
      <CardItems
        title={
          <div className={classes.titleCard}>
            <div style={{ width: '50%' }}>
              {form[0]}
            </div>
            <EditActions 
              info={info}
              formIsValid={formIsValid}
              inEdit={onEdit}
              submitHandler={submitHandler}
              onEditOrCancelClick={onEditOrCancelItemClick}
              onGuardarCambiosClick={onGuardarCambiosClick}
              onEliminarClick={onEliminarClick}
              itemTooltip="sección"
            />
          </div>
        }
        body={
          <div className={classes.cardsItems}>
            <div className={classes.checkboxListContainer}>
              {formChecks.map((form, index) => (
                <Fragment key={index}>
                  {form}
                </Fragment>
              ))}
            </div>
          </div>
        }
      />    
    </form>
  )
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateItem: (item, success, error) => dispatch(actions.crearPerfil(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarPerfil(item, success, error)),
    onDeleteItem: (id, success, error) => dispatch(actions.eliminarPerfil(id, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPerfil);