import React from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions";

import Menu from "../UI/Menu/Menu";
import AvatarUsuarioLog from "../UI/AvatarUsuarioLog/AvatarUsuarioLog";
import FormAjustesUsuario from "../Configuraciones/FormAjustesUsuario/FormAjustesUsuario";

const listItems = [
  {
    id: "ajustes",
    title: "Ajustes",
  },
  {
    id: "logout",
    title: "Cerrar sesión",
  },
];

const MenuUsuario = (props) => {
  const { onShowModal } = props;

  const onItemClick = (selectedItem) => {
    switch (selectedItem.id) {
      case "logout":
        return window.location.replace("/logout");

      case "ajustes":
        return onShowModal(selectedItem.title, <FormAjustesUsuario />);

      default:
        break;
    }
  };

  return (
    <div>
      <Menu
        placement={"bottom-end"}
        email={sessionStorage.getItem("userEmail")}
        title={<AvatarUsuarioLog />}
        listItems={listItems}
        onItemClick={(selectedItem) => onItemClick(selectedItem)}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowModal: (title, body) => {
      dispatch(
        actions.showGlobalModal(title, body, undefined, false, true, "sm")
      );
    },
  };
};

export default connect(null, mapDispatchToProps)(MenuUsuario);
