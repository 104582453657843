import React from "react";
import classes from "./PerfilesClientes.css";
import ListUsersDataTable from "../../components/ListUsersDataTable/ListUsersDataTable";

const PerfilesClientes = () => {
  return (
    <div className={classes.ContainerPermisos}>      
      <div className={classes.ContainerUsuarios}>
        <div className={classes.CardUsuarios}>
          <ListUsersDataTable />
        </div>
      </div>
    </div>
  );
}

export default PerfilesClientes;