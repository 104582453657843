import React, { useState, useEffect, useCallback } from "react";
import * as actions from "../../../store/actions";
import { connect } from "react-redux";
import classes from "../Styles/Permisos.css";
import { Typography } from "@material-ui/core";
import Spinner from "../../UI/Spinner/Spinner";
import { CardItems, NewActions, SelectControl } from "../Common";
import { CardPlan } from "..";
import { Fragment } from "react";

const CardPlanes = (props) => {
  const {
    loading,
    estaCargado,
    onLoadItems,
    loadingAplicaciones,
  } = props;
  
  const [nuevoItem, setNuevoItem] = useState(false);
  const [item, setItem] = useState();
  const [loadingItem, setLoadingItem] = useState(false);

  useEffect(() => {
    if(!estaCargado)
      onLoadItems();
  }, [estaCargado, onLoadItems]);

  const onValueSelectChange = (item) => {
    setLoadingItem(true);
    setItem(item);
    setTimeout(() => setLoadingItem(false), 500);
  }

  const updateOnSuccess = useCallback((item) => {
    onLoadItems();
    setItem(item);
  }, [onLoadItems]);

  const onNuevoItemClick = () => {
    setItem(null);
    setNuevoItem(!nuevoItem);
  }

  return (
    <CardItems
      title={
        <div>
          <div className={classes.titleCard}>
            <Typography align="left" style={{ color: "#00BCD4", fontSize: '18px' }}>
              Planes
            </Typography>
            <NewActions nuevoItem={nuevoItem} setNuevoItem={onNuevoItemClick} title="Nuevo" />
          </div>
          <div className={classes.titleCard}>
            {loading && loadingAplicaciones ? (
              <div className={classes.spinnerItems}>
                <Spinner />
              </div>
            ) : !nuevoItem && (
              <SelectControl 
                dataSelectionId="planes"
                onSelectChange={onValueSelectChange}                
                selectedItem={item}
              />
            )}
          </div>   
        </div>  
      }
      body={
        <div className={classes.cardsItems}>
          {loadingItem ? (
            <div className={classes.spinnerItems}>
              <Spinner />
            </div>
          ) : (
            <Fragment>              
              {nuevoItem && (
                <CardPlan 
                  nuevo 
                  close={() => setNuevoItem(!nuevoItem)}                  
                  onSuccessUpdateOrCreate={updateOnSuccess}
                />
              )}
              {item &&
                <CardPlan 
                  info={item} 
                  onSuccessUpdateOrCreate={updateOnSuccess}
                />
              }  
            </Fragment>)}
        </div>
      }
    />
  );
}

const mapStateToProps = (state) => ({
  items: state.permisos.loadPlanes.items,
  loading: state.permisos.loadPlanes.loading,
  estaCargado: state.permisos.loadPlanes.estaCargado,  
  loadingAplicaciones: state.permisos.loadAplicaciones.loading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadItems: () => dispatch(actions.loadPlanes()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPlanes);