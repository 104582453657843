import { Avatar, IconButton, Tooltip } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { CloseOutlined, EditOutlined } from "@material-ui/icons";
import React, { createRef, useState } from "react";
import styled from "styled-components";
import UserImageDefault from "../../../assets/images/user_default.png";

const CenteredContent = styled.div`
  text-align: center;
`;

const BigAvatar = styled(Avatar)`
  width: 60px;
  height: 60px;
  margin: 0 auto ;
  ${({ $withBorder }) =>
    $withBorder &&
    `border: 1px solid ${grey[500]};
     box-shadow: 0 0 1px 0 ${grey[500]} inset, 0 0 1px 0 ${grey[500]};`}
`;

const AvatarUpload = (props) => {
  const [image, _setImage] = useState(props.image);
  const inputFileRef = createRef(null);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    inputFileRef.current.value = null;
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
    props.setImage && props.setImage(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target?.files?.[0];

    if (newImage) {
      setImage(URL.createObjectURL(newImage));
    }
  };

  /**
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event
   */
  const handleClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };

  return (
    <CenteredContent>
      <BigAvatar
        $withBorder
        alt="Avatar"
        src={image || UserImageDefault}
        imgProps={{
          style: {
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "cover",
          },
        }}
      />
      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id="avatar-image-upload"
        type="file"
        onChange={handleOnChange}
      />
      {/*<label htmlFor="avatar-image-upload">
        <Tooltip title={image ? "Eliminar imagen" : "Editar imagen"}>
          <IconButton
            variant="contained"
            component="span"
            mb={2}
            onClick={handleClick}
          >
            {image ? <CloseOutlined mr={2} /> : <EditOutlined mr={2} />}
          </IconButton>
        </Tooltip>
      </label>*/}
    </CenteredContent>
  );
};

export default AvatarUpload;