import * as actionTypes from "./actionTypes";

export const showGlobalModal = (
  title,
  body,
  footer,
  disableCloseButton,
  fullWidth,
  maxWidth
) => {
  return {
    type: actionTypes.SHOW_GLOBAL_MODAL,
    title,
    body,
    footer,
    disableCloseButton,
    fullWidth,
    maxWidth,
  };
};

export const changeGlobalModalBody = (newBody) => {
  return {
    type: actionTypes.CHANGE_GLOBAL_MODAL_BODY,
    newBody,
  };
};

export const hiddenGlobalModal = () => {
  return {
    type: actionTypes.HIDDEN_GLOBAL_MODAL,
  };
};
