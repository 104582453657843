import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import classes from "../Styles/Permisos.css";
import { EditActions } from "../Common";
import { checkValidity } from "../../../assets/shared/utility";
import { Button, Chip, Typography } from "@material-ui/core";
import { customControls } from '../Common/Controls';

const CardSeccion = (props) => {
  const {
    nuevo,
    aplicacionId,
    info,
    index,
    close,
    clientes,
    crear,
    editar,
    eliminar,
  } = props;

  const [onEdit, setOnEdit] = useState(false);
  const [clientesSeccion, setClientesSeccion] = useState([]);

  const buildChips = useCallback((secciones) => {
    let items = [];

    secciones.forEach((secCli) => {
      const item = clientes.find((cliente) => cliente.clienteIdPg === secCli.clienteId);

      if(item !== undefined)
        items.push(item);
    });      
    setClientesSeccion(items);
  }, [clientes]);

  useEffect(() => {
    if(!nuevo && info !== undefined && info.seccionesClientes !== undefined && clientes !== undefined){
      buildChips(info.seccionesClientes);
    }
  }, [buildChips, clientes, info, nuevo]);

  //#region Form

  const [controls, setControls] = useState({
    nombreItem: {
      elementType: "input",
      name: "nombreItem",
      elementConfig: {
        type: "text",
        placeholder: "Nombre sección",
      },
      validation: {
        required: true,
      },
      required: true,
      disabled: info ? true : false,
      value: info ? info.nombre : "",
      valid: true,
      touched: false,
      errorMessage: "",
      elementStyle: { width: '100%' }
    },
    consultorCheck: {
      elementType: "checkbox",
      name: "consultorCheck",
      label: "Sección consultor",
      elementConfig: {
        type: "checkbox",
      },
      validation: {},
      required: true,
      disabled: info ? true : false,
      value: info ? info.consultor : false,
      valid: true,
      touched: false,
      errorMessage: "",
    },
    aMedidaCheck:{
      elementType: "checkbox",
      name: "aMedidaCheck",
      label: "Sección a medida",
      elementConfig: {
        type: "checkbox",
      },
      validation: {},
      disabled: info ? true : false,
      value: info ? info.aMedida : false,
      valid: true,
      touched: false,
      errorMessage: "",
    },
    clienteSelect: {
      elementType: "select",
      name: "clienteSelect",
      label: "Cliente",
      items: clientes.map((cliente) => { return { id: cliente.clienteId, nombre: cliente.razonSocial }}),
      elementConfig: {
        type: "select",
      },
      validation: {
        required: true,
      },
      required: true,
      disabled: info ? true : false,
      value: '',
      valid: true,
      touched: false,
      errorMessage: "",
    }
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    const value = updatedControl.elementType === "checkbox" 
      ? event.target.checked 
      : updatedControl.elementType === "select" 
        ? event
        : event.target.value;
    
    updatedControl.value = value;
    const [isValid, errorMessage] = checkValidity(value, controls[inputId].validation);
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  let form = customControls(controls, inputValueChangedHandler);

  //#endregion

  //#region Actions
  const getItem = () => {
    let seccionesClientes = [];

    clientesSeccion.forEach((secCli) => {
      seccionesClientes.push({ 
        clienteId: secCli.clienteIdPg ? secCli.clienteIdPg : 0, 
        seccionId: info ? info.id : 0
      });
    });

    const item = {
      id: info ? info.id : 0,
      nombre: controls.nombreItem.value,
      aMedida: controls.aMedidaCheck.value,
      consultor: controls.consultorCheck.value,
      seccionesClientes: seccionesClientes,
    }

    return item;
  }

  const submitHandler = () => {
    let seccion = getItem();
    
    const onSuccess = (data) => {
      crear(data);
    }

    const onError = (error) => {
      console.log(error);
    }

    if(aplicacionId !== 0){
      seccion.aplicacionId = aplicacionId;

      props.onCreateItem(seccion, onSuccess, onError);
    }else{
      crear(seccion);
    }

    if(nuevo)
      close();
  }

  const onEditOrCancelItemClick = (restartValues) => {
    const updatedControls = { ...controls };

    for (let key in controls) {
      updatedControls[key].disabled = !controls[key].disabled;
    }

    if (restartValues) {
      updatedControls.nombreItem.value = info.nombre;
      updatedControls.nombreItem.valid = true;
      updatedControls.aMedidaCheck.value = info.aMedida;
      updatedControls.aMedidaCheck.valid = true;
      updatedControls.consultorCheck.value = info.consultor;
      updatedControls.consultorCheck.valid = true;

      buildChips(info.seccionesClientes);
    }

    setFormIsValid(true);
    setControls(updatedControls);
    setOnEdit(!onEdit);
  };

  const onGuardarCambiosClick = () => {
    let seccion = getItem();

    const onSuccess = (data) => {
      editar(data, index);
    }

    const onError = (error) => {
      console.log(error);
    }

    if(aplicacionId !== 0){
      seccion.aplicacionId = aplicacionId;

      props.onUpdateItem(seccion, onSuccess, onError);
    }else{
      editar(seccion, index);
    }    
  }

  const onEliminarClick = () => {
    const onSuccess = (success) => {
      if(success){
        eliminar(index);
      }
    }

    const onError = (error) => {
      console.log(error);
    }

    if(info !== undefined && info.id !== undefined && info.id !== 0){
      props.onDeleteItem(info.id, onSuccess, onError);
    }else{
      eliminar(index);
    }
  }

  const onAgregarCliente = () => {
    if(controls.clienteSelect !== undefined && controls.clienteSelect.valid){
      const key = controls.clienteSelect.value;

      const noExisteKey = (clientesSeccion.find((item) => item.clienteId === key)) === undefined;

      if(noExisteKey){
        const cliente = clientes.find((item) => item.clienteId === controls.clienteSelect.value.id);
  
        if(cliente !== undefined){
          const items = [...clientesSeccion, cliente];
          setClientesSeccion(items);
          controls.clienteSelect.value = {};
          controls.clienteSelect.valid = true;
        }
      }
    }
  }

  const onRemoverCliente = (cliente) => {
    const items = clientesSeccion.filter((item) => item.clienteId !== cliente.clienteId);
    setClientesSeccion(items);
  };

  //#endregion

  return (
    <div className={classes.cardContainer}>
      <form>
        <div className={classes.titleCard} style={{ paddingLeft: '20px' }}>
          <div style={{ width: '50%' }}>
            {form[0]}
          </div>
          <EditActions 
            info={info}
            formIsValid={formIsValid}
            inEdit={onEdit}
            submitHandler={submitHandler}
            onEditOrCancelClick={onEditOrCancelItemClick}
            onGuardarCambiosClick={onGuardarCambiosClick}
            onEliminarClick={onEliminarClick}
            itemTooltip="sección"
          />
        </div>
        <div className={classes.rowFlex}>
          <div style={{ width: '27%' }}>
            {form[1]}
          </div>
          <div style={{ width: '27%' }}>
            {form[2]}
          </div>
          <div style={{ width: '30%' }}>
            {form[3]}
          </div>
          {(nuevo || onEdit) && (
            <div style={{ width: '20%', textAlign: 'end' }}>
              <Button 
                variant="contained"
                classes={{
                  root: classes.actionButton,
                  label: classes.actionButtonLabel,
                }}
                onClick={() => onAgregarCliente()}
                disabled={!nuevo && !onEdit}
              >
                Agregar
              </Button>
            </div>
          )}
        </div>
        {clientesSeccion.length !== 0 && (
          <div>
            <div className={classes.titleCard}>
              <Typography align="left" style={{ color: "#00BCD4", fontSize: '12px', paddingLeft: '15px' }}>
                Clientes a medida
              </Typography>
            </div>
            <div className={classes.clientesSeccionContainer}>
              {clientesSeccion.map((cliente) => (
                <Chip
                  key={cliente.clienteId}
                  label={cliente.razonSocial}
                  disabled={!nuevo && !onEdit}
                  onDelete={() => onRemoverCliente(cliente)}
                  className={classes.clientesSeccionChip}
                  size="small"
                  classes={{
                    labelSmall: classes.clientesSeccionChipLabel
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  clientes: state.clientes.clientes.clientes,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateItem: (item, success, error) => dispatch(actions.crearSeccion(item, success, error)),
    onUpdateItem: (item, success, error) => dispatch(actions.actualizarSeccion(item, success, error)),
    onDeleteItem: (id, success, error) => dispatch(actions.eliminarSeccion(id, success, error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CardSeccion);