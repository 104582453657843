import React from "react";
import MaterialTable from "material-table";

const Table = (props) => {
  const {
    title,
    columns,
    data,
    loading,
    actions,
    components,
    customOptions,
    actionsTitle,
    editable,
    parentChildData,
    style
  } = props;

  return (
    <MaterialTable
      style={style}
      title={title}
      columns={columns}
      editable={{
        ...editable,
      }}
      data={data}
      isLoading={loading}
      actions={actions}
      components={components}
      parentChildData={parentChildData}
      localization={{
        header: {
          actions: actionsTitle,
        },
        pagination: {
          labelDisplayedRows: '{from}-{to} de {count}',
          labelRowsSelect: 'Filas',
          previousTooltip: 'Página anterior',
          nextTooltip: 'Página siguiente',
          firstTooltip: 'Primer página',
          lastTooltip: 'Última página'
        },
        toolbar: {
          searchTooltip: 'Buscar',
          searchPlaceholder: 'Buscar',
          exportName: 'Descargar en CSV',
          exportAriaLabel: 'Exportar',
          exportTitle: 'Exportar',
          addRemoveColumns: 'add col'
        },
        body: {
          editTooltip: 'Editar',
          addTooltip: 'Nuevo',
          deleteTooltip: 'Eliminar',
          emptyDataSourceMessage: loading ? 'Cargando...' : 'No hay datos para mostrar',
          editRow: {
            cancelTooltip: 'Cancelar',
            saveTooltip: 'Confirmar',
            deleteText: '¿Confirma que quiere eliminar?',
          }
        }
      }}
      options={
        customOptions
          ? customOptions
          : {
              actionsColumnIndex: !props.actionsInFirstColumn ? -1 : 0,
              grouping: false,
              draggable: false,
              columnResizable: false,
              tableLayout: "auto",
              rowStyle:{
                fontSize: 14,
                padding: 0
              },
              padding:"dense",
              headerStyle:{
                padding:0
              }
            }
      }
    />
  );
};

export default Table;
