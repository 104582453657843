import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LogoNav from "../../assets/images/SoloLogoSup.png";

import MenuUsuario from "../../components/MenuUsuario/MenuUsuario";
import ModalGlobal from "../../components/UI/ModalGlobal/ModalGlobal";
import Notifier from '../../components/UI/Notifier/Notifier';
import { storageNombreAplicacion } from "../../assets/shared/sessionData";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    flex: 1,
    height: "100vh",
  },
  root: {
    display: "flex",
    flex: 1,
    flexGrow: 1,
    maxHeight: "56px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: "center",
    fontSize: "xxx-large",
    cursor: "context-menu",
    display: "flex",
    fontFamily: "Righteous",
    marginTop: "-8px",
    fontStyle: "normal",
    color: "white",
  },
  body: {
    display: "flex",
    flex: 16,
    background: "#E5E5E5 0% 0% no-repeat padding-box",
  },
  Logo: {
    flexGrow: 1,
    fontSize: 'xxx-large',
    cursor: 'context-menu',
    display: 'flex',
    fontFamily: 'Righteous',
    fontStyle: 'normal'
  },
  appTitle: {
    display: 'flex', 
    marginRight: '15px', 
    fontSize: 'x-large', 
    fontFamily: 'Righteous',
    color: 'white'
  }
}));

const Layout = (props) => {
  const classes = useStyles();
  return (
    <div className={classes.layoutContainer}>
      <div className={classes.root}>
        <AppBar
          style={{ backgroundColor: "RGB(81, 81, 81)" }}
          position="static"
        >
          <Toolbar style={{ minHeight: "55px" }}>
            {window.location.pathname !== "/" && (
              <ArrowBackIcon
                onClick={() => window.location.replace("/")}
                style={{
                  marginRight: "20px",
                  color: "white",
                  backgroundColor: "rgb(60 60 60)",
                  borderRadius: "20px",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
              />
            )}
            <div className={classes.Logo}>
              <img alt="" src={LogoNav} />
              <div className={classes.appTitle} style={{ alignSelf: 'center' }}>
                {storageNombreAplicacion()}
              </div>
            </div>
            {<MenuUsuario />}
          </Toolbar>
        </AppBar>
      </div>
      <ModalGlobal />
      <Notifier />
      <div className={classes.body}>{props.children}</div>
    </div>
  );
};

export default Layout;
