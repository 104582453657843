import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utilities";

const initialState = {
  gruposDeVendedoresDelCliente: {
    gruposDeVendedores: [],
    loading: false,
    error: null,
  },
};

//#region loadGruposDeVendedoresDelCliente
const loadGruposDeVendedoresDelClienteStart = (state) => {
  return updateObject(state, {
    gruposDeVendedoresDelCliente: updateObject(
      state.gruposDeVendedoresDelCliente,
      {
        loading: true,
      }
    ),
  });
};

const loadGruposDeVendedoresDelClienteSuccess = (state, gruposDeVendedores) => {
  return updateObject(state, {
    gruposDeVendedoresDelCliente: updateObject(
      state.gruposDeVendedoresDelCliente,
      {
        gruposDeVendedores,
        loading: false,
      }
    ),
  });
};

const loadGruposDeVendedoresDelClienteFail = (state, error) => {
  return updateObject(state, {
    gruposDeVendedoresDelCliente: updateObject(
      state.gruposDeVendedoresDelCliente,
      {
        loading: false,
        error,
      }
    ),
  });
};
//#endregion

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_START:
      return loadGruposDeVendedoresDelClienteStart(state);
    case actionTypes.LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_SUCCESS:
      return loadGruposDeVendedoresDelClienteSuccess(
        state,
        action.gruposDeVendedores
      );
    case actionTypes.LOAD_GRUPO_DE_VENDEDORES_DEL_CLIENTE_FAIL:
      return loadGruposDeVendedoresDelClienteFail(state, action.error);

    default:
      return state;
  }
};

export default reducer;
