// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TableDashboardUserConfig__SpinnerEmpresas__aeBHi {\r\n  display: flex;\r\n  flex: 1 1;\r\n  justify-content: center;\r\n}\r\n\r\n.TableDashboardUserConfig__TieneAccesoCheck__k7e5y {\r\n  text-align: left;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SpinnerEmpresas": "TableDashboardUserConfig__SpinnerEmpresas__aeBHi",
	"TieneAccesoCheck": "TableDashboardUserConfig__TieneAccesoCheck__k7e5y"
};
export default ___CSS_LOADER_EXPORT___;
