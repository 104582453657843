import React from "react";
import { CardAplicaciones, CardPerfiles, CardPlanes } from "../../components/Permisos";
import classes from "./Permisos.css";

const Permisos = () => {
  return (
    <div className={classes.ContainerPermisos}>
      <div className={classes.ContainerAplicaciones}>
        <div className={classes.CardAplicaciones}>
          <CardAplicaciones />
        </div>
      </div>
      
      <div className={classes.ContainerPlanesPerfiles}>
        <div className={classes.CardPlanes}>
          <CardPlanes />
        </div>
        <div className={classes.CardPerfiles}>
          <CardPerfiles />
        </div>
      </div>
    </div>
  );
}

export default Permisos;