// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".App__App__fb839 {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100vh;\r\n  justify-content: center;\r\n  align-items: center;\r\n  transition: 1s;\r\n}\r\n\r\n@media (max-width: 750px) {\r\n  .App__App__fb839 {\r\n    height: initial;\r\n  }\r\n}\r\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"App": "App__App__fb839"
};
export default ___CSS_LOADER_EXPORT___;
