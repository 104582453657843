import React from "react";
import * as actions from "../../../store/actions";

import Dialog from "../../UI/Dialog/Dialog";
import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import classes from "./ModalUserAppsConfig.css";

import { connect } from "react-redux";
import TableDashboardConfig from "../TableDashboardUserConfig/TableDashboardUserConfig";
import TableVendedoresUserConfig from "../TableVendedoresUserConfig/TableVendedoresUserConfig";

const ModalUserAppsConfig = (props) => {
  const bodyContent = () => {
    switch (props.appSelected) {
      case "Empresas":
        return <TableDashboardConfig />;

      case "Vendedor":
        return <TableVendedoresUserConfig />;

      default:
        break;
    }
  };

  return (
    <Dialog
      title={
        <div classes={classes.titleContent} style={{ width: "100%" }}>
          <div className={classes.titleTexto}>
            <Typography variant="h6">
              Configuración {props.appSelected}
            </Typography>
            <IconButton aria-label="close" onClick={() => props.onCloseModal()}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      }
      fullWidth
      maxWidth={"sm"}
      show={props.show}
      body={bodyContent()}
      onClose={props.onCloseModal}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userId: state.configuraciones.dashboardUserConfig.userId,
    userEmail: state.configuraciones.dashboardUserConfig.userEmail,
    show: state.configuraciones.dashboardUserConfig.show,
    appSelected: state.configuraciones.dashboardUserConfig.appConfig,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCloseModal: () => dispatch(actions.closeModalUserConfig()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalUserAppsConfig);
