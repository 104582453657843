import { IconButton, Typography } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "../../UI/Dialog/Dialog";

import classes from "./ModalGlobal.css";

const ModalGlobal = (props) => {
  return (
    <Dialog
      title={
        props.title ? (
          <div classes={classes.TitleDialogContent} style={{ width: "100%" }}>
            <div className={classes.TitleDialogTexto}>
              <Typography variant="h5">{props.title}</Typography>
              {!props.disableCloseButton && (
                <div>
                  <IconButton
                    aria-label="close"
                    onClick={() => props.onHiddenModal()}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              )}
            </div>
          </div>
        ) : undefined
      }
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      show={props.show}
      body={props.content}
      onClose={() => props.onHiddenModal()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    show: state.modals.globalModal.show,
    title: state.modals.globalModal.title,
    content: state.modals.globalModal.body,
    footer: state.modals.globalModal.footer,
    disableCloseButton: state.modals.globalModal.disableCloseButton,
    fullWidth: state.modals.globalModal.fullWidth,
    maxWidth: state.modals.globalModal.maxWidth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onHiddenModal: () => dispatch(actions.hiddenGlobalModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalGlobal);
