import React from "react";
import Avatar from "@material-ui/core/Avatar";

const AvatarUsuarioLog = () => {
  const email = sessionStorage.getItem("userEmail").toLowerCase();
  if (email) {
    var email_analizado = email.split("@");
    return (
      <Avatar style={{ backgroundColor: "#f15a29" }} className={"avatarUsu"}>
        {email_analizado &&
          email_analizado
            .map((item, index) => item.charAt(0).toUpperCase())
            .slice(0, 2)
            .join("")}
      </Avatar>
    );
  }
};

export default AvatarUsuarioLog;
