const idIndicadores = {
  ventasDelMes: 1,
  ventasDelDia: 2,
  ivaFiscalDelMes: 3,
  cobranzasDelMes: 4,
  promDiasDePagos: 5,
  promDiasDeCobranzas: 6,
  gastosPorCategoria: 7,
  chequesEnCartera: 8,
  chequesEmitidos: 9,
  plazoMedioVencACobrar: 10,
  plazoMedioVencAPagar: 11,
  gastosDelMes: 12,
  pagosDelMes: 13,
  saldoAcreedores: 14,
  saldoDeudores: 15,
  chequesEnCarteraMultiempresa: 16,
  chequesEmitidosPendientesMultiempresa: 17,
  gastosDelMesMultiempresa: 18,
  ventasDiariasMultiempresa: 19,
  ivaFiscalMultiempresa: 20,
  ventasMensualesMultiempresa: 21,
  pagosDelMesMultiempresa: 22,
  saldoAcreedoresMultiempresa: 23,
  saldoDeudoresMultiempresa: 24,
  cobranzasMensualesMultiempresa: 25,
  logIn: 26,
  monedas: 27,
  cambiarMoneda: 28,
  monedaDefecto: 29,
  comprobantesCobranzas: 30,
  comprobantesCompras: 31,
  comprobantesGastos: 32,
  comprobantesPagos: 33,
  comprobantesVentas: 34,
  categoriaDeItems: 35,
  comprobantesPedidos: 36,
  indicadoresOcultosPorUsuario: 37,
  indicadoresPorUsuario: 38,
  evolucionGastosMesual: 39,
  GastosPorItemDeCategoriaDelMes: 40,
  cantidadPedidosPendientes: 41,
  pedidosDelMesComparativo: 42,
  estadosPedidosPendientes: 43,
  facturasDelMesInteranual: 44,
  pedidosDelMesInteranual: 45,
  facturasPendientes: 46,
  ventasAlClienteEvolutivo: 47,
  comisionComparativo: 48,
  comisionEvolutivo: 49,
  chequesRechazados: 50,
  vendedores: 51,
  vendedoresPorEmpresa: 52,
  empresasConVendedor: 53,
  clientesInfoMinima: 54,
  clienteInfoMedia: 55,
  ultimaCompra: 56,
  lineChequesEnCartera: 57,
  lineChequesEmitidosPendientes: 58,
};

export default idIndicadores;
