// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PerfilesClientes__ContainerPermisos__HrwWM {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: row;\r\n}\r\n\r\n.PerfilesClientes__ContainerPerfiles__hvzf5 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.PerfilesClientes__CardPerfiles__iZ32g {\r\n  display: block;\r\n  margin: 10px !important;\r\n}\r\n\r\n.PerfilesClientes__ContainerUsuarios__\\+BTL4 {\r\n  display: flex;\r\n  flex: 1 1;\r\n  flex-direction: column;\r\n}\r\n\r\n.PerfilesClientes__CardUsuarios__d0wKc {\r\n  display: block;\r\n  margin: 20px 10px 20px 10px;\r\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ContainerPermisos": "PerfilesClientes__ContainerPermisos__HrwWM",
	"ContainerPerfiles": "PerfilesClientes__ContainerPerfiles__hvzf5",
	"CardPerfiles": "PerfilesClientes__CardPerfiles__iZ32g",
	"ContainerUsuarios": "PerfilesClientes__ContainerUsuarios__+BTL4",
	"CardUsuarios": "PerfilesClientes__CardUsuarios__d0wKc"
};
export default ___CSS_LOADER_EXPORT___;
