import * as actionTypes from "./actionTypes";
import axios from "../../axios-instance";
import idIndicadores from "../../assets/shared/idIndicadores";

export const loadVendedores = () => {
  return (dispatch) => {
    dispatch(loadVendedoresStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axios
      .get(`/vendedores?indicadorId=${idIndicadores.vendedores}`, {
        headers: { Authorization: authStr },
      })
      .then((response) => {
        dispatch(loadVendedoresSuccess(response.data));
      })
      .catch((error) => {
        dispatch(loadVendedoresFail(error));
      });
  };
};

const loadVendedoresStart = () => {
  return {
    type: actionTypes.LOAD_VENDEDORES_START,
  };
};

const loadVendedoresSuccess = (vendedores) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_SUCCESS,
    vendedores,
  };
};

const loadVendedoresFail = (error) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_FAIL,
    error,
  };
};

export const loadVendedoresPorEmpresa = (dsn, success) => {
  return (dispatch) => {
    dispatch(loadVendedoresPorEmpresaStart());
    const authStr = "Bearer ".concat(sessionStorage.getItem("token"));

    axios
      .get(
        `/vendedores/vendedoresPorEmpresa?dsn=${dsn}&indicadorId=${idIndicadores.vendedoresPorEmpresa}`,
        {
          headers: { Authorization: authStr },
        }
      )
      .then((response) => {
        dispatch(loadVendedoresPorEmpresaSuccess(response.data));
        return success(response.data);
      })
      .catch((error) => {
        dispatch(loadVendedoresPorEmpresaFail(error));
      });
  };
};

const loadVendedoresPorEmpresaStart = () => {
  return {
    type: actionTypes.LOAD_VENDEDORES_POR_EMPRESA_START,
  };
};

const loadVendedoresPorEmpresaSuccess = (vendedores) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_POR_EMPRESA_SUCCESS,
    vendedores,
  };
};

const loadVendedoresPorEmpresaFail = (error) => {
  return {
    type: actionTypes.LOAD_VENDEDORES_POR_EMPRESA_FAIL,
    error,
  };
};
