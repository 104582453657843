import React, { useState } from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";

import classes from "./FormAjustesUsuario.css";
import { checkValidity } from "../../../assets/shared/utility";

const FormAjustesUsuario = (props) => {
  const { usuarioInfo, onGuardarCambios, onHiddenModal } = props;

  const [formIsValid, setFormIsValid] = useState(false);
  const [controls, setControls] = useState({
    name: {
      name: "nombre",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Nombre",
      },
      value: usuarioInfo.nombre,
      validation: {
        required: true,
      },
      valid: usuarioInfo.nombre,
      touched: false,
      errorMessage: "",
    },
    lastName: {
      name: "apellido",
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: "Apellido",
      },
      value: usuarioInfo.apellido,
      validation: {
        required: true,
      },
      valid: usuarioInfo.apellido,
      touched: false,
      errorMessage: "",
    },
    currentPassword: {
      name: "currentPassword",
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Contraseña actual",
      },
      value: "",
      validation: {
        required: true,
        containSimbol: true,
        containNumber: true,
        containMayus: true,
        containMinus: true,
      },
      valid: false,
      touched: false,
      errorMessage: "",
    },
    newPassword: {
      name: "newPassword",
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Nueva contraseña",
      },
      value: "",
      validation: {
        minLength: 6,
        required: true,
        containSimbol: true,
        containNumber: true,
        containMayus: true,
        containMinus: true,
      },
      valid: false,
      touched: false,
      errorMessage: "",
    },
    repeatPassword: {
      name: "repeatPassword",
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: "Repetir contraseña",
      },
      value: "",
      validation: {
        required: true,
        minLength: 6,
        containSimbol: true,
        containNumber: true,
        containMayus: true,
        containMinus: true,
      },
      valid: false,
      touched: false,
      errorMessage: "",
    },
  });

  const formElementsArray = [];
  for (let key in controls) {
    formElementsArray.push({ id: key, ...controls[key] });
  }

  let form = formElementsArray.map((elem) => (
    <Input
      key={elem.id}
      name={elem.name}
      elementType={elem.elementType}
      elementConfig={elem.elementConfig}
      value={elem.value}
      valueChanged={(event) => inputValueChangedHandler(event, elem.id)}
      invalid={!elem.valid}
      shouldValidate={elem.validation}
      touched={elem.touched}
      errorMessage={elem.errorMessage}
      disabled={elem.disabled}
      autoComplete={"false"}
    />
  ));

  const inputValueChangedHandler = (event, inputId) => {
    const updatedControl = { ...controls[inputId] };
    updatedControl.value = event.target.value;
    const [isValid, errorMessage] = checkValidity(
      event.target.value,
      controls[inputId].validation
    );
    updatedControl.valid = isValid;
    updatedControl.errorMessage = errorMessage;
    updatedControl.touched = true;

    const updatedControls = {
      ...controls,
      [inputId]: updatedControl,
    };

    let formIsValid = true;
    for (let formElementKey in updatedControls) {
      formIsValid =
        formIsValid &&
        (!updatedControls[formElementKey].validation ||
          updatedControls[formElementKey].valid);
    }

    setControls(updatedControls);
    setFormIsValid(formIsValid);
  };

  const onGuardarCambiosClick = (e) => {
    e.preventDefault();
    if (controls.newPassword.value !== controls.repeatPassword.value) {
      return alert("Las contraseñas no coniciden");
    }

    const userModificado = {
      nombre: controls.name.value,
      apellido: controls.lastName.value,
      password: controls.currentPassword.value,
      nuevoPassword: controls.newPassword.value,
    };

    onGuardarCambios(usuarioInfo.id, userModificado, () => {
      onHiddenModal();
    });
  };

  return (
    <form className={classes.FormContainer}>
      <div className={classes.FlexHorizontal}>
        {form[0]}
        <div className={classes.Separador} />
        {form[1]}
      </div>
      <div className={classes.FlexHorizontal} style={{ maxWidth: "54%" }}>
        {form[2]}
      </div>
      <div className={classes.FlexHorizontal}>
        {form[3]}
        <div className={classes.Separador} />

        {form[4]}
      </div>
      <div style={{ alignSelf: "flex-end" }}>
        <Button
          disabled={!formIsValid}
          onClick={(e) => onGuardarCambiosClick(e)}
        >
          Guardar Cambios
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  usuarioInfo: state.usuarios.userInfo,
});

const mapDispatchToProps = (dispatch) => {
  return {
    onHiddenModal: () => dispatch(actions.hiddenGlobalModal()),
    onGuardarCambios: (userId, userModificado, success) =>
      dispatch(actions.ActualizarPassword(userId, userModificado, success)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormAjustesUsuario);
