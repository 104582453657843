import React, { Fragment } from "react";
import { Typography } from "@material-ui/core";

const NewActions = (props) =>{
  const {
    nuevoItem,
    setNuevoItem,
    title,
  } = props;

  return (
    <Fragment>
      <Typography
        align="right"
        style={{
          color: "#00BCD4",
          cursor: "pointer",
          fontSize: '10px'
        }}
        variant="button"
        onClick={() => setNuevoItem(!nuevoItem)}
      >
        {nuevoItem ? "- Cancelar" : title ? `+ ${title}` : "+ Nueva"}
      </Typography>
    </Fragment>
  )
}

export default NewActions;